import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";

interface IProps {
  name: string;
  icon: IconProp;
  link: string;
}

const QuickLinkItem = (props: IProps) => {
  const headerStyle: CSSProperties = {
    background: "#dbe7f0",
    color: "3a7a86",
    width: "90%",
    height: "100%",
    verticalAlign: "center",
  };

  const btnBox: CSSProperties = {
    padding: "10px",
  };

  const marginTop: CSSProperties = {
    marginTop: "10px",
  };

  const btnText: CSSProperties = {
    fontSize: "0.65vw",
  };

  const btnIcon: CSSProperties = {
    fontSize: "2.5vw",
  };

  return (
    <div className="col-lg-4" style={btnBox}>
      <a type="button" className="btn" href={props.link} style={headerStyle}>
        <div className="row">
          <div className="col-lg-12 centeredColumn" style={marginTop}>
            <FontAwesomeIcon
              icon={props.icon}
              size="3x"
              color="#3a7a86"
              style={btnIcon}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 centeredColumn" style={marginTop}>
            <h6 style={btnText}>{props.name}</h6>
          </div>
        </div>
      </a>
    </div>
  );
};

export default QuickLinkItem;
