import React, { CSSProperties, useEffect, useState } from "react";
import { IReview } from "../../models/reviewModels/IReview";
import { process, filterBy } from "@progress/kendo-data-query";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { getCurrentReviews } from "../../apis/reviewApi";
import { appInsights } from "../../utils/initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { getLatestDocuments } from "../../apis/homepageApi";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { IReviewGridModel } from "../../models/reviewModels/IReviewGridModel";
import {
  IGridDataSortOrder,
  getGridDataState,
} from "../common/grid/gridDataState";
import {
  customCellRender,
  customHeaderRender,
} from "../common/grid/customCellRender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../App.css";
import GridLabel from "../common/gridLabel";
import { LoadingImage } from "../common/loadingImage";
import {
  mapHomepageGridData,
  mapReviewsGridData,
} from "../common/gridDataHelper";
import { IHomepageDocument } from "../../models/IHomepageDocument";
import { IHomepageDocumentGridModel } from "../../models/IHomepageDocumentGridModel";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { CustomCard } from "../common/customCard";

const DocumentReviewReport = () => {
  const gridStyle: CSSProperties = {
    // padding: "20px",
    margin: "30px",
  };

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewsList, setReviewsList] = useState<
    Array<IHomepageDocumentGridModel>
  >([]);
  const [dataState, setDataState] = useState(
    getGridDataState("formattedBeginDate", IGridDataSortOrder.desc, true, 20)
  );

  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  useEffect(() => {
    const getLatestDocumentsFromApi = async () => {
      try {
        setLoading(true);
        setError(false);
        let documents: IHomepageDocument[];

        documents = await getLatestDocuments();
        const mappedGridData = mapHomepageGridData(documents, []);
        setReviewsList(mappedGridData);
        setLoading(false);
      } catch (error: any) {
        setError(true);
        appInsights.trackException({
          error,
          id: "Fetching all Reviews",
          severityLevel: SeverityLevel.Critical,
          properties: {
            serverResponse: `${error.response}`,
          },
        });
      }
    };

    getLatestDocumentsFromApi();
  }, []);

  if (loading) {
    return <LoadingImage />;
  }

  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  return (
    <>
      <CustomCard headerText="Recently Updated Documents">
        <ExcelExport
          data={filterBy(reviewsList, dataState.filter!)}
          ref={(exporter) => {
            _export = exporter;
          }}
          fileName="Reports"
        >
          <Grid
            sortable
            filterable
            resizable
            reorderable
            pageable={{ buttonCount: 4, pageSizes: true }}
            data={process(reviewsList, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            cellRender={customCellRender}
            headerCellRender={customHeaderRender}
          >
            <GridToolbar className="k-text-end">
              <div className="row">
                <div className="col-lg-12">
                  <button
                    type="button"
                    title="Export to Excel"
                    className="btn btn-primary"
                    onClick={exportExcel}
                  >
                    <FontAwesomeIcon icon="file-excel" /> Export to Excel
                  </button>
                </div>
              </div>
            </GridToolbar>
            <GridColumn
              className="centeredColumn"
              field="programArea"
              title="Program Area"
            />
            <GridColumn
              className="centeredColumn"
              field="office"
              title="Office"
            />
            <GridColumn
              className="centeredColumn"
              field="documentTitle"
              title="Document Title"
            />
            <GridColumn
              className="centeredColumn"
              field="topicNumber"
              title="Topic Number"
            />
            <GridColumn
              className="centeredColumn"
              field="documentType"
              title="Document Type"
            />

            <GridColumn
              className="centeredColumn"
              field="requestType"
              title="Status"
            />

            <GridColumn
              className="centeredColumn"
              field="DocumentOwner"
              title="Document Owner"
            />
          </Grid>
        </ExcelExport>
        <GridLabel />
      </CustomCard>
    </>
  );
};

export default DocumentReviewReport;
