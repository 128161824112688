"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var logService_1 = require("./../services/logService");
exports.formatPhoneNumber = function (phoneNumber, extension) {
    if (phoneNumber === undefined)
        return '';
    if (phoneNumber.length === 10) {
        var formatted = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
        return extension !== undefined && extension.length > 0
            ? formatted + " (" + extension + ")"
            : formatted;
    }
    else if (phoneNumber.length === 7) {
        var formatted = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 4);
        return extension !== undefined && extension.length > 0
            ? formatted + " (" + extension + ")"
            : formatted;
    }
    else {
        logService_1.logException("Implement formatPhoneNumber logic for: " + phoneNumber);
        return phoneNumber;
    }
};
