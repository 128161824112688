// import { IStaff } from "./../models/IStaff";
import { IStaff } from "procedural-document-library-shared";
import { formatPhoneNumber } from "./phoneNumberUtils";
import { IStaffMember } from "@fdot/arculus-staff-service";

export const staffMap = (staff: IStaffMember): IStaff => {
  return {
    srsId: staff.id,
    emailAddress: staff.emailAddress || "",
    firstName: staff.firstName,
    lastName: staff.lastName,
    racfId: staff.racfId,
    phoneNumber: formatPhoneNumber(staff.phone, staff.phoneExtension),
    positionWorkTitle:
      staff.positionWorkTitle !== undefined && staff.positionWorkTitle !== null
        ? staff.positionWorkTitle
        : "",
    azureAdOid: staff.azureAdOid,
    // connectorIds: staff.staffConnectors ? staff.staffConnectors.map(i => i.id) : []
  };
};

export const getUserDisplay = (
  staff: IStaff | undefined | null,
  includeEmail?: boolean,
  includePhone?: boolean,
  includeRacf?: boolean,
  includePositionWorkTitle?: boolean,
  showFirstNameFirst?: boolean
): string => {
  if (staff) {
    let display =
      showFirstNameFirst === true
        ? `${staff.firstName} ${staff.lastName}`
        : `${staff.lastName}, ${staff.firstName}`;

    if (includeEmail === true && staff.emailAddress) {
      display += ` (${staff.emailAddress})`;
    }
    if (includePhone === true && staff.phoneNumber) {
      display += ` [${staff.phoneNumber}]`;
    }
    if (includeRacf === true && staff.racfId) {
      display += ` (${staff.racfId})`;
    }
    if (includePositionWorkTitle === true) {
      if (
        staff.positionWorkTitle === undefined ||
        staff.positionWorkTitle === null ||
        staff.positionWorkTitle.trim() === ""
      ) {
        display += ` - Unknown Position Title`;
      } else {
        display += ` - ${staff.positionWorkTitle}`;
      }
    }
    return display;
  }
  return "N/A";
};

export const getUsersDisplay = (
  staff: Array<IStaff> | undefined | null,
  includeEmail?: boolean,
  includePhone?: boolean,
  includeRacf?: boolean
): string => {
  if (staff === undefined || staff === null || staff.length === 0) {
    return "N/A";
  }

  return staff
    .map((i) => getUserDisplay(i, includeEmail, includePhone, includeRacf))
    .join();
};

export const getUserInitials = (user: IStaff | null | undefined): string => {
  if (user === null || user === undefined) {
    return "?";
  }
  let initials = "";
  if (user.firstName && user.firstName.length > 0) {
    initials += user.firstName[0];
  }
  if (user.lastName && user.lastName.length > 0) {
    initials += user.lastName[0];
  }
  return initials;
};

export const getUserFullName = (user: IStaff | null | undefined) => {
  let fullName = "";
  if (user === null || user === undefined) return fullName;

  if (
    user.firstName &&
    user.firstName.length > 0 &&
    user.lastName &&
    user.lastName.length > 0
  ) {
    fullName = `${user.lastName}, ${user.firstName}`;
    return fullName;
  } else {
    return fullName;
  }
};
