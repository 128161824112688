import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { InitializeAxios } from "./apis/axiosInit";
import { initializeIcons } from "./components/icons";

import "@progress/kendo-theme-bootstrap/dist/all.css";

const App: React.FC = (props) => {
  initializeIcons();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
      ></ToastContainer>
      <BrowserRouter>
        <InitializeAxios />
        <AppRoutes />
      </BrowserRouter>
    </>
  );
};

export default App;
