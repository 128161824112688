import React, { CSSProperties } from "react";
import "../../../App.css"

interface IProps {
  thProps: any;
}

// export const headerFontSize: CSSProperties = { fontSize: "10px" };

export const CustomHeaderCell = (props: IProps) => {
  return <a {...props.thProps} title={props.thProps.children[0]} className="centeredColumn" />;
};
