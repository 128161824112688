import React from "react";

const GridLabel = () => {
  return (
    <>
      <br />
      <div>
        <p className="text-dark">
          *Columns may be adjusted on the grid above by hovering over the grid
          line and then clicking and dragging until the column has reached the
          desired size.
        </p>
      </div>
    </>
  );
};

export default GridLabel;
