import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./rootReducer";
import thunkMiddleware from "redux-thunk";
import { officeApi } from "./slices/responsibleOfficesApi";
import { requestFormActionApi } from "./slices/requestFormActionApi";
import { documentContactChangeApi } from "./slices/documentContactChangeApi";
import { announcementApi } from "./slices/announcementsApi";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(thunkMiddleware)
      .concat(logger)
      .concat(
        officeApi.middleware,
        requestFormActionApi.middleware,
        documentContactChangeApi.middleware,
        announcementApi.middleware
      ),
});

if (process.env.NODE_ENV === "development" && (module as any).hot) {
  (module as any).hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
