import axios from "axios";
import { IHomepageDocument } from "../models/IHomepageDocument";

export const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/homepage`;

export const getLatestDocuments = async (): Promise<
  Array<IHomepageDocument>
> => {
  const response = await axios.get(`${baseUrl}/getLatestDocuments`);
  return response.data.homepageDocuments;
};

export const searchDocuments = async (
  criteria: any
): Promise<Array<IHomepageDocument>> => {
  const response = await axios.post(`${baseUrl}/searchDocuments`, criteria);
  return response.data.homepageDocuments;
};
