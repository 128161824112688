import axios from "axios";
import { IUser } from "procedural-document-library-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/user`;

export const getUser = async (): Promise<IUser> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const impersonate = async (srsId: number): Promise<IUser> => {
  const response = await axios.post(`${baseUrl}/impersonate`, { srsId });
  return response.data;
};
