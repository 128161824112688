import React from "react";
import { CustomHeaderCell } from "./customHeaderCell";
import { FocusableCell } from "./focusableCell";

export const customCellRender = (e: any) => {
  return <FocusableCell tdProps={e.props} />;
};

export const customHeaderRender = (e: any) => {
  return <CustomHeaderCell thProps={e.props} />;
};
