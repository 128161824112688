import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import {
  ILoggingService,
  logService,
  IAppConfig,
} from "procedural-document-library-shared";
import {
  staffService,
  IStaffServiceOptions,
  Environment,
} from "@fdot/arculus-staff-service";

export var appInsights: ApplicationInsights;

export const initializeApp = (config: IAppConfig, displayName: string) => {
  loadAppInsights(config.APP_INSIGHTS_KEY);
  appInsights.setAuthenticatedUserContext(displayName);
  initializeSharedServices(config);
};

const loadAppInsights = (applicationInsightsKey?: string) => {
  if (appInsights) return;

  // Create React App Note
  // There is also a built-in environment variable called NODE_ENV.
  // You can read it from process.env.NODE_ENV. When you run npm start,
  // it is always equal to 'development', when you run npm test it is
  // always equal to 'test', and when you run npm run build to make a
  // production bundle, it is always equal to 'production'. You cannot
  // override NODE_ENV manually. This prevents developers from accidentally
  // deploying a slow development build to production.
  if (
    //process.env.NODE_ENV === "production" &&
    fdot.process.env.APP_ENVIRONMENT === "production" &&
    applicationInsightsKey
  ) {
    // Currently only logging to app insights for the client in production
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: applicationInsightsKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
      },
    });
    appInsights.loadAppInsights();
  } else {
    appInsights = ({
      trackEvent: () => {},
      trackException: () => {},
      setAuthenticatedUserContext: () => {},
    } as any) as ApplicationInsights;
  }
};

const initializeSharedServices = (config: IAppConfig) => {
  if (config.ARCULUS_API_KEY) {
    const staffServiceOptions: IStaffServiceOptions = {
      arculusApiKey: config.ARCULUS_API_KEY,
      //environment: process.env.NODE_ENV === "production"
      environment:
        fdot.process.env.APP_ENVIRONMENT === "production"
          ? Environment.Production
          : Environment.Test,
      cacheOptions: {
        cacheDurationInMinutes: 60,
        cachingEnabled: true,
        slidingExpirationEnabled: true,
      },
    };

    staffService.initialize(staffServiceOptions);
  }

  const loggingService: ILoggingService = {
    log: (item: any) => console.log(item),

    logException: (error: any) => {
      const exceptionDetails = {
        error,
        id: "Exception",
        severityLevel: SeverityLevel.Critical,
      };
      appInsights.trackException(exceptionDetails);
    },
  };

  logService.initialize(loggingService);
};
