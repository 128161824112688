import React from "react";

const anchorColor = {
  color: "#33557c",
};

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-md-center">
        <nav className="navbar fixed-bottom navbar-light bg-light">
          <div className="col text-center">
            Procedural Document Library
            <br />
            Report technical problems to the&nbsp;
            <a
              href="mailto:FDOT.ServiceDesk@dot.state.fl.us?subject=Procedural Document Library - Service Desk"
              title="Use this link to E-Mail concerns, problems, and/or comments"
              style={anchorColor}
            >
              Service Desk
            </a>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="Web Policies and Notices (Opens new browser window)"
              href="https://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm"
              style={anchorColor}
            >
              Web Policies and Notices
            </a>
            &nbsp; - &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fdot.gov/agencyresources/notices/accessibility.shtm"
              title="Accessibility Statement  (Opens new browser window)"
              style={anchorColor}
            >
              Accessibility Statement
            </a>
            &nbsp; - &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.myflorida.com/"
              title="MyFlorida.com  (Opens new browser window)"
              style={anchorColor}
            >
              MyFlorida.com
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
