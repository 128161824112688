import axios from "axios";
import { IProcedure } from "../models/procedureModels/IProcedure";
import { IVersion } from "../models/procedureModels/IVersion";
import { IProcedureComment } from "../models/procedureModels/IProcedureComment";

export const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/procedures`;

const multipartHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const saveProcedure = async (procedure: IProcedure) => {
  const response = await axios.post(`${baseUrl}/saveProcedure`, procedure);
  return response.data;
};

export const updateProcedure = async (procedure: IProcedure) => {
  const response = await axios.patch(`${baseUrl}/updateProcedure`, procedure);
  return response.data;
};

export const uploadDocument = async (formData: FormData) => {
  const response = await axios.post(
    `${baseUrl}/uploadFile`,
    formData,
    multipartHeaders
  );
  return response.data.fileResults;
};

export const updateDocument = async (formData: FormData, versionId: string) => {
  const response = await axios.post(
    `${baseUrl}/updateDocument/${versionId}`,
    formData
  );
  return response.data;
};

export const deleteAttachmentById = async (
  docId: number,
  versionId: string
) => {
  const response = await axios.delete(
    `${baseUrl}/deleteAttachmentById/${docId}/${versionId}`
  );
  return response.data;
};

export const getProcedureByIdPromise = (id: string) => {
  return axios.get(`${baseUrl}/getProcedureById/${id}`);
};

export const getProcedureById = async (id: string): Promise<IProcedure> => {
  const response = await axios.get(`${baseUrl}/getProcedureById/${id}`);
  return response.data.procedure;
};

export const getProcedureName = async (id: string): Promise<string> => {
  const response = await axios.get(`${baseUrl}/getProcedureName/${id}`);
  return response.data.procedureNumber;
};

export const getAllProcedures = async (): Promise<Array<IProcedure>> => {
  const response = await axios.get(`${baseUrl}/getAllProcedures`);
  return response.data.allProcedures;
};

export const getCurrentProcedures = async (): Promise<Array<IProcedure>> => {
  const response = await axios.get(`${baseUrl}/getCurrentProcedures`);
  return response.data.currentProcedures;
};

export const getProcedureByText = async (
  searchText: string,
  includePlanned: boolean
): Promise<IProcedure[]> => {
  const response = await axios.get(
    `${baseUrl}/getProcedureByText/${searchText}/${includePlanned}`
  );
  return response.data;
};

export const getVersionById = async (id: string): Promise<IVersion> => {
  const response = await axios.get(`${baseUrl}/getVersionById/${id}`);
  return response.data.version;
};

export const getLatestVersion = async (
  procedureId: string
): Promise<IVersion> => {
  const response = await axios.get(
    `${baseUrl}/getLatestVersion/${procedureId}`
  );
  return response.data.version;
};

export const saveVersion = async (version: FormData, procedureId?: string) => {
  const response = await axios.post(
    `${baseUrl}/saveVersion/${procedureId}`,
    version
  );
  return response.data;
};

export const updateVersion = async (
  version: FormData,
  procedureId?: string
) => {
  const response = await axios.patch(
    `${baseUrl}/updateVersion/${procedureId}`,
    version
  );
  return response.data;
};

export const deleteVersion = async (
  versionId: string,
  procedureId?: string
) => {
  const response = await axios.get(
    `${baseUrl}/deleteVersion/${versionId}/${procedureId}`
  );
  return response.data;
};

export const submitProcedureQuestionComment = async (
  formValues: IProcedureComment
) => {
  const response = await axios.post(
    `${baseUrl}/procedureQuestionComment`,
    formValues
  );
  return response.data;
};

export const getProceduresForContactOwners = async (): Promise<
  Array<IProcedure>
> => {
  const response = await axios.get(`${baseUrl}/contactProcedureOwners`);
  return response.data;
};

export const submitContactProcedureOwners = async (formData: FormData) => {
  const response = await axios.post(
    `${baseUrl}/contactProcedureOwners`,
    formData
  );
  return response.data;
};

export const getVersionsByProcedureId = async (
  procedureId?: string
): Promise<Array<IVersion>> => {
  const response = await axios.get(
    `${baseUrl}/getVersionsByProcedureId/${procedureId}`
  );
  return response.data.versions;
};

export const getMasterDataReport = async () => {
  const response = await axios.get(`${baseUrl}/masterDataReport`);
  return response.data;
};
