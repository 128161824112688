import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  message: string;
  isOpen: boolean;
  isOpenChanged?: (isOpen: boolean) => void;
}

const ExternalRefMessageModal = (props: IProps) => {
  const { message, isOpen, isOpenChanged } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    if (isOpenChanged) {
      isOpenChanged(!modal);
    }
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={toggle}>
        {"Title - External Information"}
      </ModalHeader>
      <ModalBody>
        <div className="col-form-label font-weight-normal">{message}</div>
      </ModalBody>
      <ModalFooter>
        <button id="cancel" className="btn btn-danger" onClick={toggle}>
          <FontAwesomeIcon icon="times-circle" /> Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ExternalRefMessageModal;
