import React, { useEffect, useState, CSSProperties, useRef } from "react";
import { CustomCard } from "../common/customCard";
import {
  getAdminForms,
  baseUrl,
  getExternalForms,
  getInternalForms,
  filterForms,
} from "../../apis/formApi";
import { IForm } from "../../models/formModels/IForm";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process, filterBy } from "@progress/kendo-data-query";
import { appInsights } from "../../utils/initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../utils/history";
import { isNullOrUndefinedOrWhiteSpace } from "../../utils/stringUtils";
import { RootState } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import FormDetailsModal from "./formDetailsModal";
import { IFormGridModel } from "../../models/formModels/IFormGridModel";
import { mapFormGridData } from "../common/gridDataHelper";
import { getUserRole } from "../../utils/userUtils";
import ErrorPage from "../common/errorPage";
import { LoadingImage } from "../common/loadingImage";
import { SingleStaffSelector } from "../common/singleStaffSelector";
import { IStaff, IUserRole } from "procedural-document-library-shared";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  getGridDataState,
  IGridDataSortOrder,
} from "../common/grid/gridDataState";
import FormsGridAttachmentCell from "../common/grid/formsGridAttachmentCell";
import {
  customCellRender,
  customHeaderRender,
} from "../common/grid/customCellRender";
import {
  IFormSearchInputs,
  loadingCompleted,
  loadingStarted,
  updateSearch,
} from "../../redux/slices/formsSearchSlice";
import _ from "lodash";
import GridLabel from "../common/gridLabel";
import localforage from "localforage";
import ExternalRefMessageModal from "./ExternalRefMessageModal";
import { useNavigate } from "react-router-dom";

export const divMarginBottom: CSSProperties = {
  marginBottom: "10px",
  verticalAlign: "center",
};
export const fdotPrimaryButton: CSSProperties = {
  backgroundColor: "#33557c",
  borderColor: "#33557c",
};

const Forms = () => {
  const navigate = useNavigate();
  // Removing Deep Linking here and move it to HomePage.tsx
  // const redirectIfUserInitiallyTriedToDeepLinkBeforeAuthenticated = () => {
  //   localforage.getItem("deep-link-redirect-url").then((redirectUrl: any) => {
  //     if (redirectUrl) {
  //       localforage.removeItem("deep-link-redirect-url").then(() => {
  //         window.location.href = redirectUrl;
  //       });
  //     }
  //   });
  // };
  // redirectIfUserInitiallyTriedToDeepLinkBeforeAuthenticated();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialSearchInputs: IFormSearchInputs = {
    contact: null,
    includeInactiveForms: false,
    viewType: IUserRole.Admin,
    disableIncludeInactive: false,
    searchTerm: "",
    pageSize: 10,
    highlightedRowIndex: -1,
    sortColumn: "formNumber",
    sortDirection: "asc",
  };
  const { searchInputs } = useSelector(
    (state: RootState) => state.formsSearchReducer
  );
  const [includeInactive, setIncludeInactive] = useState(
    searchInputs.includeInactiveForms
  );
  const [formsList, setFormsList] = useState<Array<IFormGridModel>>([]);
  const [form, setForm] = useState<IForm>();
  const [dataState, setDataState] = useState(
    getGridDataState(
      searchInputs.sortColumn,
      searchInputs.sortDirection === "asc"
        ? IGridDataSortOrder.asc
        : IGridDataSortOrder.desc,
      true,
      searchInputs.pageSize
    )
  );
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchContact, setSearchContact] = useState<IStaff | null>(
    searchInputs.contact
  );
  const [isExternalRefModalOpen, setIsExternalRefModalOpen] = useState(false);
  const [message, setExternalMessage] = useState("");
  const [initialForms, setInitialForms] = useState<Array<IFormGridModel>>([]);
  const [gridView, setGridView] = useState<IUserRole>(searchInputs.viewType);
  const [disableIncludeInactive, setDisableIncludeInactive] = useState(
    searchInputs.disableIncludeInactive
  );
  const [includeInactiveStaff, setIncludeInactiveStaff] = useState(false);
  const [searchTerms, setSearchTerm] = useState(searchInputs.searchTerm);
  const [pageSize, setPageSize] = useState(searchInputs.pageSize);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(
    searchInputs.highlightedRowIndex
  );
  const [sortColumn, setSortColumn] = useState(searchInputs.sortColumn);
  const [sortDirection, setSortDirection] = useState(
    searchInputs.sortDirection
  );

  //useState vs useRef : we don't want this variable to cause re-render so useref is used.
  const isFirstRun = useRef(true);

  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  const externalMessageHandler = (message: string) => {
    setIsExternalRefModalOpen(true);
    setExternalMessage(message);
  };

  const attachmentsCell = (event: any) => {
    return (
      <FormsGridAttachmentCell
        gridDataItem={event.dataItem}
        onOtherClick={externalMessageHandler}
      />
    );
  };

  const actionsCell = (event: any) => {
    const item: IFormGridModel = event.dataItem;
    return (
      <td>
        {user && isAuthenticated && user.isAdmin && (
          <button
            id={`edit_${item._id}`}
            title="Edit Form"
            className="btn btn-primary btn-sm"
            onClick={() => navigate(`/Forms/edit/${item._id}`)}
            style={fdotPrimaryButton}
          >
            <FontAwesomeIcon icon="edit" /> Edit
          </button>
        )}
        <button
          id={`details_${item._id}`}
          key={`details_${item._id}`}
          className="btn btn-primary btn-sm"
          title="Form Details"
          onClick={() => {
            setIsModalOpen(true);
            setForm(item);
          }}
          style={fdotPrimaryButton}
        >
          <FontAwesomeIcon icon="info-circle" /> Details
        </button>

        {/* {item.externalReferenceMessage && (
          <button
            id={`Other_${item._id}`}
            key={`Other_${item._id}`}
            title="Other"
            className="btn btn-primary btn-sm"
            onClick={() => {
              setIsExternalRefModalOpen(true);
              setExternalMessage(item.purpose);
            }}
            style={fdotPrimaryButton}
          >
            <FontAwesomeIcon icon="edit" /> PopUp
          </button>
        )} */}
      </td>
    );
  };

  const procedureCell = (event: any) => {
    const item: IFormGridModel = event.dataItem;
    const emptyTd: JSX.Element = <td></td>;
    const procedure = item.relatedProcedure;
    if (procedure === null || procedure === undefined) {
      return emptyTd;
    }

    if (!isNullOrUndefinedOrWhiteSpace(procedure.documentLink)) {
      return (
        <td>
          <a
            id={`procedure_${procedure._id}`}
            href={`${procedure.documentLink}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-info btn-sm text-white"
            title={`Opens Procedure in new window. Procedure: ${procedure.title}`}
            style={fdotPrimaryButton}
          >
            {procedure.procedureNumber}
          </a>
        </td>
      );
    }

    if (procedure.versions.length > 0) {
      const attachments = procedure.versions[0].attachments;
      if (attachments && attachments.length > 0) {
        const pdfAttachment = attachments.filter(
          (x) => x.extension === "pdf" && x.docId !== undefined
        );
        if (pdfAttachment === undefined) {
          return emptyTd;
        }
        return (
          <td>
            <a
              key={pdfAttachment[0].docId}
              id={`procedure_${procedure._id}`}
              href={`${fdot.process.env.BACKEND_SERVER_HOST}/api/procedures/downloadProcedure/${procedure.procedureNumber}`}
              rel="noopener noreferrer"
              className="btn btn-info btn-sm text-white"
              title={`Downloads attachment to your browser. Attachment name is ${procedure.procedureNumber}.${pdfAttachment[0].extension} (${attachments[0].fileSize} bytes).`}
              style={fdotPrimaryButton}
            >
              {procedure.procedureNumber}
            </a>
          </td>
        );
      }
    }

    return emptyTd;
  };

  const gridRowClick = (event: any) => {
    const role = getUserRole(user, isAuthenticated);
    if (role === IUserRole.Admin) {
      let last = lastSelectedIndex;
      const data = formsList;
      const current = data.findIndex((dataItem) => dataItem === event.dataItem);

      if (!event.nativeEvent.shiftKey) {
        last = current;
        setLastSelectedIndex(last);
        dispatch(
          updateInputs(
            searchContact,
            includeInactive,
            gridView,
            disableIncludeInactive,
            searchTerms,
            pageSize,
            last,
            sortColumn,
            sortDirection
          )
        );
      }

      if (!event.nativeEvent.ctrlKey) {
        data.forEach((item) => (item.selected = false));
      }

      const select = !event.dataItem.selected;
      for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
        data[i].selected = select;
      }
      setFormsList(data);
    }
  };

  const updateInputs =
    (
      contact: IStaff | null,
      inactiveForms: boolean,
      viewMode: IUserRole,
      disableIncludeInactive: boolean,
      term: string,
      pageSize: number,
      highlightedRowIndex: number,
      sortColumn: string,
      sortDirection: string
    ) =>
    async (dispatch: any) => {
      const searchInputs: IFormSearchInputs = {
        contact,
        includeInactiveForms: inactiveForms,
        viewType: viewMode,
        disableIncludeInactive,
        searchTerm: term,
        pageSize: pageSize,
        highlightedRowIndex: highlightedRowIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      dispatch(loadingStarted());
      dispatch(updateSearch(searchInputs));
      dispatch(loadingCompleted());
    };

  const handleFilters = async (
    contact: IStaff | null,
    inactiveForms: boolean,
    viewMode: IUserRole,
    searchTerm: string,
    lastSelectedIndex: number
  ) => {
    try {
      const srsId = contact !== null ? contact.srsId.toString() : null;
      if (
        inactiveForms === false &&
        srsId === null &&
        viewMode === IUserRole.Admin &&
        searchTerm === ""
      ) {
        setIncludeInactive(inactiveForms);
        if (initialForms.length > 0) {
          initialForms.forEach((item) => (item.selected = false));
          setFormsList(initialForms);
        } else {
          try {
            setLoading(true);
            setError(false);
            const adminMappedForms = mapFormGridData(await getAdminForms());
            setFormsList(adminMappedForms);
            setInitialForms(adminMappedForms);
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            setError(true);
            appInsights.trackException({
              error,
              id: "Fetching forms for admin in handle filters",
              severityLevel: SeverityLevel.Critical,
              properties: {
                selectedContact: contact,
                inactiveForms,
                viewMode,
              },
            });
          }
        }
      } else {
        setLoading(true);
        setError(false);
        const search =
          searchTerm === undefined || searchTerm === "" ? null : searchTerm;
        const response = await filterForms(
          srsId,
          inactiveForms,
          viewMode,
          search
        );
        const data = mapFormGridData(response);
        setFormsList(data);
        if (lastSelectedIndex !== -1) {
          data[lastSelectedIndex].selected = true;
        } else {
          data.forEach((item) => (item.selected = false));
        }
        setLoading(false);
      }
      setIncludeInactive(inactiveForms);
      setSearchContact(contact);
      setGridView(viewMode);
      //setSearchTerm(searchTerm);
    } catch (error: any) {
      setLoading(false);
      setError(true);
      appInsights.trackException({
        error,
        id: "Fetching forms by filters",
        severityLevel: SeverityLevel.Critical,
        properties: {
          selectedContact: contact,
          inactiveForms,
          viewMode,
        },
      });
    }
  };

  const handleClearFilters = () => {
    if (disableIncludeInactive) {
      setDisableIncludeInactive(false);
    }
    if (
      includeInactive ||
      searchContact !== null ||
      gridView !== IUserRole.Admin ||
      searchTerms !== ""
    ) {
      setIncludeInactive(false);
      setSearchContact(null);
      setGridView(IUserRole.Admin);
      setSearchTerm("");
    }

    setPageSize(10);
    setIncludeInactiveStaff(false);
    setLastSelectedIndex(-1);
    dispatch(
      updateInputs(
        null,
        false,
        IUserRole.Admin,
        false,
        "",
        10,
        -1,
        "formNumber",
        "asc"
      )
    );
    handleFilters(null, false, IUserRole.Admin, "", -1);
  };

  useEffect(() => {
    const userRole = getUserRole(user, isAuthenticated);

    const getForms = async (role: IUserRole) => {
      try {
        setLoading(true);
        setError(false);
        let forms;
        switch (role) {
          case IUserRole.External:
            forms = await getExternalForms();
            break;
          case IUserRole.Internal:
            forms = await getInternalForms();
            break;
          case IUserRole.Admin:
            forms = await getAdminForms();
            break;
        }
        const mappedGridData = mapFormGridData(forms);
        setFormsList(mappedGridData);
        setInitialForms(mappedGridData);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setError(true);
        appInsights.trackException({
          error,
          id: "Fetching Forms",
          severityLevel: SeverityLevel.Critical,
          properties: {
            userRole: role,
          },
        });
      }
    };

    if (_.isEqual(initialSearchInputs, searchInputs)) {
      getForms(userRole);
    } else {
      handleFilters(
        searchInputs.contact,
        searchInputs.includeInactiveForms,
        searchInputs.viewType,
        searchInputs.searchTerm,
        searchInputs.highlightedRowIndex
      );
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!isFirstRun.current) {
      const delayDebounceFn = setTimeout(
        () =>
          handleFilters(
            searchContact,
            includeInactive,
            gridView,
            searchTerms,
            -1
          ),
        2000
      );

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerms]);

  if (error) {
    return <ErrorPage model="Forms" />;
  }

  if (loading) {
    return <LoadingImage />;
  }

  const getFormFilters = () => {
    return user && isAuthenticated && user.isAdmin ? (
      <div className="card card-body bg-light">
        <div className="row">
          <div className="col-lg-2">
            <div className="form-check">
              <input
                className="form-check-input"
                name="includeInactiveForms"
                id="includeInactiveForms"
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setLastSelectedIndex(-1);
                  dispatch(
                    updateInputs(
                      searchContact,
                      checked,
                      gridView,
                      disableIncludeInactive,
                      searchTerms,
                      pageSize,
                      -1,
                      "formNumber",
                      "asc"
                    )
                  );
                  handleFilters(
                    searchContact,
                    checked,
                    gridView,
                    searchTerms,
                    -1
                  );
                }}
                checked={includeInactive}
                disabled={disableIncludeInactive}
              />
              <label
                className="form-check-label fw-normal"
                htmlFor="includeInactiveForms"
                title="Include Inactive Forms"
              >
                Include Inactive Forms
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-check">
              <input
                name="includeInactiveStaff"
                id="includeInactiveStaff"
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  setSearchContact(null);
                  const checked = e.target.checked;
                  if (checked) {
                    setIncludeInactiveStaff(true);
                  } else {
                    setIncludeInactiveStaff(false);
                  }
                }}
                checked={includeInactiveStaff}
              />
              <label
                className="form-check-label fw-normal"
                htmlFor="includeInactiveStaff"
                title="Include Inactive Staff"
              >
                Include Inactive Staff
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <SingleStaffSelector
              id="searchForFDOTUser"
              selectedStaff={searchContact}
              onChange={(selected: IStaff | null) => {
                dispatch(
                  updateInputs(
                    selected,
                    includeInactive,
                    gridView,
                    disableIncludeInactive,
                    searchTerms,
                    pageSize,
                    -1,
                    "formNumber",
                    "asc"
                  )
                );
                setLastSelectedIndex(-1);
                handleFilters(
                  selected,
                  includeInactive,
                  gridView,
                  searchTerms,
                  -1
                );
              }}
              includeInactive={includeInactiveStaff}
            />
          </div>
          <div className="col-lg-1">
            <select
              id="forms-grid-view"
              name="forms-grid-view"
              className="form-select"
              title="Select a Grid View"
              value={gridView}
              onChange={(e) => {
                const gridView: IUserRole = e.target.value as IUserRole;
                if (gridView === IUserRole.Admin) {
                  dispatch(
                    updateInputs(
                      searchContact,
                      includeInactive,
                      gridView,
                      false,
                      searchTerms,
                      pageSize,
                      lastSelectedIndex,
                      "formNumber",
                      "asc"
                    )
                  );
                  handleFilters(
                    searchContact,
                    includeInactive,
                    gridView,
                    searchTerms,
                    lastSelectedIndex
                  );
                  setDisableIncludeInactive(false);
                } else {
                  setLastSelectedIndex(-1);
                  dispatch(
                    updateInputs(
                      searchContact,
                      false,
                      gridView,
                      true,
                      searchTerms,
                      pageSize,
                      -1,
                      "formNumber",
                      "asc"
                    )
                  );
                  handleFilters(
                    searchContact,
                    false,
                    gridView,
                    searchTerms,
                    -1
                  );
                  setDisableIncludeInactive(true);
                }
              }}
            >
              <option value={IUserRole.Admin}>Admin View</option>
              <option value={IUserRole.Internal}>Internal View</option>
              <option value={IUserRole.External}>External View</option>
            </select>
          </div>
          <div className="col-lg-2">
            <input
              name="searchTermText"
              id="searchTermText"
              type="text"
              value={searchTerms}
              title="Search By Form title/number/office"
              className="form-control"
              placeholder="Search By Form title/number/office"
              onChange={(e) => {
                const search = e.target.value;
                isFirstRun.current = false;
                setSearchTerm(search);
                dispatch(
                  updateInputs(
                    searchContact,
                    includeInactive,
                    gridView,
                    disableIncludeInactive,
                    search,
                    pageSize,
                    -1,
                    "formNumber",
                    "asc"
                  )
                );
                setLastSelectedIndex(-1);
              }}
            />
          </div>
          <div className="col-lg-1">
            <button
              type="button"
              title="Clear Filters"
              className="btn btn-secondary"
              onClick={() => handleClearFilters()}
            >
              <FontAwesomeIcon icon="times-circle" /> Clear
            </button>
          </div>
          <div className="col-lg-2 text-end">
            <button
              type="button"
              title="Export to Excel"
              className="btn btn-primary"
              onClick={exportExcel}
            >
              <FontAwesomeIcon icon="file-excel" /> Export to Excel
            </button>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const getLargeGrid = () => {
    return (
      <>
        <ExcelExport
          data={filterBy(formsList, dataState.filter!)}
          ref={(exporter) => {
            _export = exporter;
          }}
          fileName="Forms"
        >
          <Grid
            sortable
            filterable
            resizable
            reorderable
            pageable={{
              buttonCount: 4,
              pageSizes: [10, 20, 50, 100, formsList.length],
            }}
            data={process(formsList, dataState)}
            {...dataState}
            selectedField="selected"
            onRowClick={gridRowClick}
            onDataStateChange={(e) => {
              const role = getUserRole(user, isAuthenticated);
              if (role === IUserRole.Admin) {
                let direction = "";
                let sortColumn = "";
                if (
                  e.dataState.sort !== undefined &&
                  e.dataState.sort.length > 0
                ) {
                  direction =
                    e.dataState.sort![0].dir !== undefined
                      ? e.dataState.sort![0].dir
                      : "";
                  sortColumn = e.dataState.sort![0].field;
                }
                dispatch(
                  updateInputs(
                    searchContact,
                    includeInactive,
                    gridView,
                    disableIncludeInactive,
                    searchTerms,
                    e.dataState.take!,
                    lastSelectedIndex,
                    sortColumn,
                    direction
                  )
                );
                setPageSize(e.dataState.take!);
                setSortColumn(sortColumn);
                setSortDirection(direction);
              }
              setDataState(e.dataState);
            }}
            cellRender={customCellRender}
            headerCellRender={customHeaderRender}
          >
            <GridColumn field="formNumber" title="Number" />
            <GridColumn width="300px" field="formTitle" title="Form Title" />
            <GridColumn
              width="200px"
              field="formattedRevisionDate"
              title="Revision Date"
              headerClassName="text-wrap"
              filter={"date"}
              format="{0:M/d/yyyy}"
            />
            <GridColumn field="formStatus" title="Status" />
            <GridColumn field="notes" title="Notes" />
            <GridColumn field="programArea" title="Program Area" />
            <GridColumn field="office" title="Office" width="175px" />
            <GridColumn
              field="procedureNumber"
              title="Procedure"
              cell={procedureCell}
            />
            <GridColumn
              title="Available Formats"
              field="attachmentTypes"
              cell={attachmentsCell}
              headerClassName="text-wrap"
            />
            <GridColumn
              filterable={false}
              sortable={false}
              title="Actions"
              cell={actionsCell}
            />
          </Grid>
        </ExcelExport>
      </>
    );
  };

  const getMidSizeGrid = () => {
    return (
      <>
        <Grid
          sortable
          filterable
          resizable
          reorderable
          pageable={{ buttonCount: 4, pageSizes: true }}
          data={process(formsList, dataState)}
          {...dataState}
          selectedField="selected"
          onRowClick={gridRowClick}
          onDataStateChange={(e) => {
            const role = getUserRole(user, isAuthenticated);
            if (role === IUserRole.Admin) {
              let direction = "";
              let sortColumn = "";
              if (
                e.dataState.sort !== undefined &&
                e.dataState.sort.length > 0
              ) {
                direction =
                  e.dataState.sort![0].dir !== undefined
                    ? e.dataState.sort![0].dir
                    : "";
                sortColumn = e.dataState.sort![0].field;
              }
              dispatch(
                updateInputs(
                  searchContact,
                  includeInactive,
                  gridView,
                  disableIncludeInactive,
                  searchTerms,
                  e.dataState.take!,
                  lastSelectedIndex,
                  sortColumn,
                  direction
                )
              );
              setPageSize(e.dataState.take!);
              setSortColumn(sortColumn);
              setSortDirection(direction);
            }
            setDataState(e.dataState);
          }}
          cellRender={customCellRender}
          headerCellRender={customHeaderRender}
        >
          <GridColumn field="formNumber" title="Number" width="200px" locked />
          <GridColumn field="formTitle" title="Form Title" width="300px" />
          <GridColumn
            field="formattedRevisionDate"
            title="Revision Date"
            width="200px"
            headerClassName="text-wrap"
            format="{0:M/d/yyyy}"
            filter={"date"}
          />
          <GridColumn field="formStatus" title="Status" width="200px" />
          <GridColumn field="programArea" title="Program Area" />
          <GridColumn field="responsibleOffice" title="Office" width="200px" />
          <GridColumn
            field="procedureNumber"
            title="Procedure"
            cell={procedureCell}
            width="200px"
          />
          <GridColumn
            title="Available Formats"
            field="attachmentTypes"
            cell={attachmentsCell}
            width="200px"
            headerClassName="text-wrap"
          />
          <GridColumn
            filterable={false}
            sortable={false}
            title="Actions"
            cell={actionsCell}
            width="200px"
          />
        </Grid>
      </>
    );
  };

  const getSmallGrid = () => {
    return (
      <>
        <Grid
          sortable
          filterable
          resizable
          reorderable
          pageable={{ buttonCount: 4, pageSizes: true }}
          data={process(formsList, dataState)}
          {...dataState}
          selectedField="selected"
          onRowClick={gridRowClick}
          onDataStateChange={(e) => {
            const role = getUserRole(user, isAuthenticated);
            if (role === IUserRole.Admin) {
              let direction = "";
              let sortColumn = "";
              if (
                e.dataState.sort !== undefined &&
                e.dataState.sort.length > 0
              ) {
                direction =
                  e.dataState.sort![0].dir !== undefined
                    ? e.dataState.sort![0].dir
                    : "";
                sortColumn = e.dataState.sort![0].field;
              }
              dispatch(
                updateInputs(
                  searchContact,
                  includeInactive,
                  gridView,
                  disableIncludeInactive,
                  searchTerms,
                  e.dataState.take!,
                  lastSelectedIndex,
                  sortColumn,
                  direction
                )
              );
              setPageSize(e.dataState.take!);
              setSortColumn(sortColumn);
              setSortDirection(direction);
            }
            setDataState(e.dataState);
          }}
          cellRender={customCellRender}
          headerCellRender={customHeaderRender}
        >
          <GridColumn width="125px" field="formNumber" title="Number" locked />
          <GridColumn width="300px" field="formTitle" title="Form Title" />
          <GridColumn
            width="200px"
            field="formattedRevisionDate"
            title="Revision Date"
            headerClassName="text-wrap"
            format="{0:M/d/yyyy}"
            filter={"date"}
          />
          <GridColumn width="200px" field="formStatus" title="Status" />
          <GridColumn field="programArea" title="Program Area" />
          <GridColumn width="200px" field="office" title="Office" />
          <GridColumn
            width="200px"
            field="procedureNumber"
            title="Procedure"
            cell={procedureCell}
          />
          <GridColumn
            width="200px"
            title="Available Formats"
            field="attachmentTypes"
            cell={attachmentsCell}
            headerClassName="text-wrap"
          />
          <GridColumn
            width="200px"
            filterable={false}
            sortable={false}
            title="Actions"
            cell={actionsCell}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <h4>Official FDOT Forms</h4>
      <div>
        <FormDetailsModal
          form={form!}
          isOpen={isModalOpen}
          isOpenChanged={(isOpen: boolean) => setIsModalOpen(isOpen)}
        />
      </div>
      <div>
        <ExternalRefMessageModal
          message={message}
          isOpen={isExternalRefModalOpen}
          isOpenChanged={(isOpen: boolean) => setIsExternalRefModalOpen(isOpen)}
        />
      </div>
      <CustomCard headerText="Forms">
        <div>
          {user && isAuthenticated && user.isAdmin && (
            <>
              <div className="row" style={divMarginBottom}>
                <div className="col-lg-12 text-end">
                  <button
                    className="btn btn-primary"
                    title="Add New Form"
                    onClick={() => navigate("/Forms/Create")}
                  >
                    <FontAwesomeIcon icon="plus-circle" /> Add New Form
                  </button>
                </div>
              </div>
            </>
          )}
          {getFormFilters()}
          <div className="d-none d-xl-block">{getLargeGrid()}</div>
          <div className="d-none d-sm-block d-xl-none">{getMidSizeGrid()}</div>
          <div className="d-block d-sm-none">{getSmallGrid()}</div>
          <GridLabel />
        </div>
      </CustomCard>
    </>
  );
};

export default Forms;
