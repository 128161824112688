import { combineReducers } from "@reduxjs/toolkit";
import configReducer from "./slices/configSlice";
import userReducer from "./slices/userSlice";
import formsSearchReducer from "./slices/formsSearchSlice";
import proceduresSearchReducer from "./slices/proceduresSearchSlice";
import { officeApi } from "./slices/responsibleOfficesApi";
import { requestFormActionApi } from "./slices/requestFormActionApi";
import { documentContactChangeApi } from "./slices/documentContactChangeApi";
import { announcementApi } from "./slices/announcementsApi";

const rootReducer = combineReducers({
  configReducer,
  userReducer,
  formsSearchReducer,
  proceduresSearchReducer,
  [officeApi.reducerPath]: officeApi.reducer,
  [requestFormActionApi.reducerPath]: requestFormActionApi.reducer,
  [documentContactChangeApi.reducerPath]: documentContactChangeApi.reducer,
  [announcementApi.reducerPath]: announcementApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
