import axios from "axios";
import { IOffice } from "../models/officeModels/IOffice";
import { IProgramAreaOffice } from "../models/areaOfficeModels/IProgramAreaOffice";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/offices`;
const url = `${baseUrl}/getOffices`;
const areaOfficeUrl = `${baseUrl}/area-offices`;

export const getOffices = async (): Promise<Array<IOffice>> => {
  const response = await axios.get(url);
  return response.data;
};

export const getAreaOffices = async (): Promise<Array<IProgramAreaOffice>> => {
  const response = await axios.get(areaOfficeUrl);
  return response.data;
};

// this method is used in axios concurrent requests
// the above and this methods are same
export const getOfficesPromise = () => {
  return axios.get(url);
};
export const getAreaOfficesPromise = () => {
  return axios.get(areaOfficeUrl);
};
