import React, { useState, useEffect, ReactNode } from "react";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export enum ExpandIconLocation {
  farLeft,
  justLeftOfText,
  justRightOfText,
  farRight,
}

export enum HeaderTextLocation {
  Left,
  Right,
  Center,
}

interface IProps {
  headerText?: string;
  additionalClass?: string;
  collapsible?: boolean;
  defaultClosed?: boolean;
  expandIconLocation?: ExpandIconLocation;
  expandIconSize?: SizeProp;
  onToggleChange?: (isOpen: boolean) => void;
  badgeValue?: string;
  badgeType?: string;
  headerTextLocation?: HeaderTextLocation;
  children?: ReactNode;
}

export const CustomCard: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.defaultClosed !== true);

  useEffect(() => {
    setIsOpen(props.defaultClosed !== true);
  }, [props.defaultClosed]);

  const defaultIconLocation = ExpandIconLocation.farRight;
  const defaultIconSize = "1x";

  const toggleOpen = () => {
    const targetIsOpen = !isOpen;
    setIsOpen(targetIsOpen);
    if (props.onToggleChange) {
      props.onToggleChange(targetIsOpen);
    }
  };

  const getIconLocation = (): ExpandIconLocation => {
    return props.expandIconLocation !== undefined
      ? props.expandIconLocation
      : defaultIconLocation;
  };

  const getIconSize = (): SizeProp => {
    return props.expandIconSize !== undefined
      ? props.expandIconSize
      : defaultIconSize;
  };

  const getClass = (): string => {
    return props.additionalClass ? `card ${props.additionalClass}` : "card";
  };

  const getExpandCollapseIcons = () => {
    if (props.collapsible !== true) {
      return null;
    }
    const location = getIconLocation();
    const iconClass: string =
      location === ExpandIconLocation.farLeft
        ? "iconBtn float-start"
        : location === ExpandIconLocation.farRight
        ? "iconBtn float-end"
        : "iconBtn";

    return isOpen === true ? (
      <button
        type="button"
        className={iconClass}
        title="Collapse"
        onClick={() => toggleOpen()}
      >
        <FontAwesomeIcon icon="minus-circle" size={getIconSize()} />
      </button>
    ) : (
      <button
        type="button"
        className={iconClass}
        title="Show"
        onClick={() => toggleOpen()}
      >
        <FontAwesomeIcon icon="plus-circle" size={getIconSize()} />
      </button>
    );
  };

  const getHeader = () => {
    if (props.headerText === undefined) {
      return null;
    }

    const location = getIconLocation();
    const badgeContent =
      props.badgeValue !== undefined ? (
        <span
          className={
            props.badgeType !== undefined
              ? `badge badge-${props.badgeType} mx-2`
              : "badge mx-2"
          }
        >
          {props.badgeValue}
        </span>
      ) : null;

    const headerContent =
      location === ExpandIconLocation.farLeft ||
      location === ExpandIconLocation.justLeftOfText ? (
        <>
          {getExpandCollapseIcons()}
          {props.headerText}
          {badgeContent}
        </>
      ) : (
        <>
          {props.headerText}
          {badgeContent}
          {getExpandCollapseIcons()}
        </>
      );

    const getHeaderTextLocation = (): string => {
      switch (props.headerTextLocation) {
        case undefined:
        case HeaderTextLocation.Center:
          return `text-center`;
        case HeaderTextLocation.Left:
          return "text-start";
        case HeaderTextLocation.Right:
          return "text-end";
        default:
          return `text-center`;
      }
    };

    return (
      <div
        title={props.headerText}
        className={`card-header ${getHeaderTextLocation()}`}
        style={{ padding: 5, fontSize: 18 }}
        onClick={() => toggleOpen()}
      >
        {headerContent}
      </div>
    );
  };

  const getBody = () => {
    return <div className="card-body">{props.children}</div>;
  };

  return (
    <div className={getClass()}>
      {props.headerText !== undefined ? getHeader() : null}
      {props.collapsible === true ? (
        <Collapse isOpen={isOpen}>{getBody()}</Collapse>
      ) : (
        getBody()
      )}
    </div>
  );
};
