import { IStaff } from "procedural-document-library-shared";
import moment from "moment";
import _ from "lodash";
import { IFileAttachment } from "../models/IAttachment";
import { IProgramAreaOffice } from "../models/areaOfficeModels/IProgramAreaOffice";

export const excelExtensionsArray = [
  "XLSX",
  "XLS",
  "XLSM",
  "XER",
  "XLSX",
  "XLSB",
];
export const wordExtensionsArray = ["DOCX", "DOC", "RTF", "DOCM"];

export const sharePointWordExtensions = ["DOCX"];

export const isNullOrUndefinedOrWhiteSpace = (
  value: string | null | undefined
): boolean => {
  return value === null || value === undefined || value.trim() === "";
};

export const isValidEmail = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined) return false;
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
};

//the below regex will match the following cases

// http://www.test.gr
// https://www.test.gr
// http://test.gr
// http://www.test.gr/kino
// http://werer.gr
// www.test.gr
// www.mp3.com
// www.t.co
// http://t.co
// http://www.t.co
// https://www.t.co
// www.aa.com
// http://aa.com
// http://www.aa.com
// https://www.aa.com

// Will NOT match the following
// www.test
// www.test-.gr
// www.-test.gr
// test.gr
// http://www.test
// http://test
// www.mp3#.com

export const isValidURL = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined) return false;
  return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
    value
  );
};

//this method is for formik input type date fields
//the formik in FMS form either takes a ISO string (yyyy-mm-dd) or a empty string
export const utcToISODateString = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  return new Date(value).toISOString().slice(0, 10);
};

export const utcToDateString = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  const d = new Date(value).toISOString().slice(0, 10);
  return moment(d).format("MM/DD/YYYY");
};

export const utcToDateStringWithTime = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  return moment(value).format("MM/DD/YYYY hh:mm:ss A");
};

export const getFullContactInformation = (contact: IStaff | null) => {
  if (contact === null || contact === undefined) return "";
  const info = [];
  info.push(`${contact.firstName ?? ""} ${contact.lastName ?? ""}`);
  if (contact.emailAddress) info.push(contact.emailAddress);
  if (contact.phoneNumber) info.push(contact.phoneNumber);

  return info.join("\n");
};

export const convertToStartCase = (value: string | null | undefined) => {
  return !isNullOrUndefinedOrWhiteSpace(value)
    ? _.startCase(value!.toLowerCase())
    : "";
};

export const getAppNameByExtension = (extension: string | null | undefined) => {
  if (!isNullOrUndefinedOrWhiteSpace(extension)) {
    if (wordExtensionsArray.indexOf(extension!.toUpperCase()) !== -1)
      return "Word";

    if (excelExtensionsArray.indexOf(extension!.toUpperCase()) !== -1)
      return "Excel";

    if (extension?.toLowerCase() === "pdf") return "PDF";

    if (extension?.toLowerCase() === "xdp") return "XDP";

    return "Other";
  } else {
    return "";
  }
};

export const getAppNameByExtensionProcedure = (
  extension: string | null | undefined,
  type: string | null | undefined
) => {
  if (!isNullOrUndefinedOrWhiteSpace(extension)) {
    if (wordExtensionsArray.indexOf(extension!.toUpperCase()) !== -1)
      return "Master";

    if (extension?.toLowerCase() === "pdf") return type;
    return "Other";
  } else {
    return "";
  }
};

export const formAttachmentHelper = (
  attachment: IFileAttachment[] | undefined,
  externalUrl: string | undefined,
  consolidatedForm = false,
  externalRefMessage = false
) => {
  let attachmentTypes: string = "";

  if (consolidatedForm) {
    attachmentTypes = "consolidated";
  } else {
    if (attachment !== undefined && attachment.length !== 0) {
      attachment.forEach((a) => {
        if (a.isAvailable) {
          let appName = getAppNameByExtension(a.extension);
          if (a.extension.toLowerCase() === "pdf" && a.isInstructionsPDF) {
            appName = "instructions";
          }
          if (attachmentTypes === "") {
            attachmentTypes = appName === undefined ? "" : appName;
          } else {
            if (appName !== undefined) {
              attachmentTypes += ` ${appName}`;
            } else {
              attachmentTypes += " ";
            }
          }
        }
      });
    }

    if (!isNullOrUndefinedOrWhiteSpace(externalUrl)) {
      attachmentTypes += " link";
    }

    if (externalRefMessage) {
      attachmentTypes += " Other";
    }
  }

  return attachmentTypes;
};

export const getFileExtensionByFileName = (
  fileName: string | undefined | null
): string => {
  if (!isNullOrUndefinedOrWhiteSpace(fileName)) {
    return fileName!.slice(fileName!.lastIndexOf(".") + 1);
  } else {
    return "";
  }
};

export const getUniqueItemsOfArray = (array: string[]) => {
  return array
    .filter((e, i) => {
      return array.indexOf(e) === i;
    })
    .sort((a, b) => a.localeCompare(b));
};

export const getProgramAreaOptions = (areaOffices: IProgramAreaOffice[]) => {
  const areas = areaOffices
    .filter((a) => a.programArea !== null)
    .map((o) => o.programArea);
  return getUniqueItemsOfArray(areas);
};

export const getOfficeOptions = (
  areaOffices: IProgramAreaOffice[],
  programArea?: string
) => {
  if (programArea) {
    const offices = areaOffices
      .filter((o) => o.programArea === programArea)
      .map((m) => m.office);
    return getUniqueItemsOfArray(offices);
  } else {
    const offices = areaOffices
      .filter((a) => a.office !== null)
      .map((o) => o.office);
    return getUniqueItemsOfArray(offices);
  }
};
