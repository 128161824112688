import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useState, useEffect } from "react";
import {
  IStaff,
  staffUtils as sharedUtils,
} from "procedural-document-library-shared";
import {
  staffUtils,
  staffService,
  IStaffMember,
} from "@fdot/arculus-staff-service";
import { getUserDisplay, staffMap } from "../../utils/staffServiceUtils";
import { Option } from "react-bootstrap-typeahead/types/types";

interface IProps {
  id: string;
  selectedStaff: IStaff | null;
  disabled?: boolean;
  onChange: (selected: IStaff | null) => void;
  includeInactive?: boolean;
}

export const SingleStaffSelector: React.FC<IProps> = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Array<IStaff>>([]);
  const [selectedStaff, setSelectedStaff] = useState<Array<IStaff>>([]);

  useEffect(() => {
    const selectedStaff =
      props.selectedStaff === null ? [] : [props.selectedStaff];
    setSelectedStaff(selectedStaff);
  }, [props.selectedStaff]);

  const onChange = (selected: Option[]) => {
    let returnVal: IStaff | null = null;
    if (selected && selected.length > 0) {
      returnVal = selected[0] as any as IStaff;
    }
    setSelectedStaff(selected as any as IStaff[]);
    props.onChange(returnVal);
  };

  const handleSearch = async (partialName: string) => {
    setIsLoading(true);
    let matches: IStaff[] = [];
    if (props.includeInactive) {
      staffService.clearCache();
      matches = await staffService.getMappedActiveAndInactiveStaffByPartialName(
        partialName,
        staffMap
      );
    } else {
      staffService.clearCache();
      matches = await staffService.getMappedActiveStaffByPartialName(
        partialName,
        staffMap
      );
    }

    setOptions(matches);
    setIsLoading(false);
  };

  const getLabel = (staff: IStaff | null | undefined): string => {
    return getUserDisplay(staff, false, false, true);
  };

  return (
    <AsyncTypeahead
      id={props.id}
      inputProps={{
        "aria-label": props.id,
        id: props.id,
        title: "Search for a FDOT User",
        name: props.id,
      }}
      allowNew={false}
      isLoading={isLoading}
      multiple={false}
      minLength={3}
      options={options}
      selected={selectedStaff}
      onSearch={handleSearch}
      onChange={onChange}
      useCache={false}
      placeholder="Search for a FDOT User"
      disabled={props.disabled}
      labelKey={(option: any) => getLabel(option)}
    />
  );
};
