import React, { useEffect, useState, CSSProperties, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldProps } from "formik";
import HomepageSearchModal from "./homepageSearchModal";
import ExternalRefMessageModal from "../forms/ExternalRefMessageModal";
import { IStaff } from "procedural-document-library-shared";
import { SingleStaffSelector } from "../common/singleStaffSelector";
import { toast } from "react-toastify";
import { appInsights } from "../../utils/initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as officeApi from "../../apis/officeApi";
import { CustomCard } from "../common/customCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

const SearchAllDocuments = () => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.userReducer
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setExternalMessage] = useState("");
  const [isExternalRefModalOpen, setIsExternalRefModalOpen] = useState(false);

  const [documentName, setDocumentName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<IStaff | null>();
  const [selectedOffice, setSelectedOffice] = useState("");
  const [offices, setOffices] = useState<string[]>([]);

  useEffect(() => {
    const getOffices = async () => {
      try {
        const officesFromApi = await officeApi.getAreaOffices();
        if (officesFromApi !== undefined) {
          let sortedOffices = officesFromApi.map((o) => o.office).sort();
          setOffices(sortedOffices);
        }
      } catch (error: any) {
        toast.error("Error while getting Offices");
        appInsights.trackException({
          error,
          severityLevel: SeverityLevel.Critical,
        });
      }
    };
    getOffices();
  }, [!offices]);

  return (
    <>
      <br />
      <CustomCard headerText="Search All Documents">
        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="documentName" title="Document Name">
                Document Name
              </label>
              <input
                name="documentName"
                className="form-control"
                title="Document Name"
                id="documentName"
                onChange={(dn) => {
                  setDocumentName(dn.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="documentNumber" title="Document Number">
                Document Number
              </label>
              <input
                name="documentNumber"
                className="form-control"
                title="Document Number"
                id="documentNumber"
                onChange={(dn) => {
                  setDocumentNumber(dn.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        {isAuthenticated && user && (
          <div className="row mb-3">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="documentOwner" title="Document Owner">
                  Document Owner
                </label>
                <SingleStaffSelector
                  id="documentOwner"
                  selectedStaff={selectedStaff ?? null}
                  onChange={(selected: IStaff | null) => {
                    setSelectedStaff(selected);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row mb-3">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="office" title="Office">
                Office
              </label>
              <select
                className="form-select"
                title="Office"
                id="office"
                onChange={(e) => setSelectedOffice(e.target.value)}
                value={selectedOffice}
              >
                <option value="">Search All Offices</option>
                {offices.map((o) => {
                  return (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12 text-center">
            <button
              id="submit"
              name="submit"
              type="submit"
              className="btn btn-primary"
              title="Submit"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon="check-circle" /> Search
            </button>
          </div>
        </div>
        <div>
          <HomepageSearchModal
            documentName={documentName}
            documentNumber={documentNumber}
            documentOwner={selectedStaff ? selectedStaff.srsId.toString() : ""}
            documentOffice={selectedOffice}
            isOpen={isModalOpen}
            isOpenChanged={(isOpen: boolean) => setIsModalOpen(isOpen)}
          />
        </div>
        <div>
          <ExternalRefMessageModal
            message={message}
            isOpen={isExternalRefModalOpen}
            isOpenChanged={(isOpen: boolean) =>
              setIsExternalRefModalOpen(isOpen)
            }
          />
        </div>
      </CustomCard>
    </>
  );
};

export default SearchAllDocuments;
