import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStaff, IUserRole } from "procedural-document-library-shared";

export interface IFormSearchInputs {
  includeInactiveForms: boolean;
  contact: IStaff | null;
  viewType: IUserRole;
  disableIncludeInactive: boolean;
  searchTerm: string;
  pageSize: number;
  highlightedRowIndex: number;
  sortColumn: string;
  sortDirection: string;
}

type SliceState = {
  loadingSearch: boolean;
  searchInputs: IFormSearchInputs;
};

const formSearchSlice = createSlice({
  name: "forms-search-input",
  initialState: {
    loadingSearch: false,
    searchInputs: {
      contact: null,
      includeInactiveForms: false,
      viewType: IUserRole.Admin,
      disableIncludeInactive: false,
      searchTerm: "",
      pageSize: 10,
      highlightedRowIndex: -1,
      sortColumn: "formNumber",
      sortDirection: "asc"
    },
  } as SliceState,
  reducers: {
    updateSearch: (
      state: SliceState,
      action: PayloadAction<IFormSearchInputs>
    ) => {
      state.searchInputs.contact = action.payload.contact;
      state.searchInputs.includeInactiveForms =
        action.payload.includeInactiveForms;
      state.searchInputs.viewType = action.payload.viewType;
      state.searchInputs.disableIncludeInactive =
        action.payload.disableIncludeInactive;
      state.searchInputs.searchTerm = action.payload.searchTerm;
      state.searchInputs.pageSize = action.payload.pageSize;
      state.searchInputs.highlightedRowIndex = action.payload.highlightedRowIndex;
      state.searchInputs.sortColumn = action.payload.sortColumn;
      state.searchInputs.sortDirection = action.payload.sortDirection;
      return state;
    },
    loadingStarted: (state: SliceState) => {
      state.loadingSearch = true;
      return state;
    },
    loadingCompleted: (state: SliceState) => {
      state.loadingSearch = false;
      return state;
    },
  },
});

export const {
  updateSearch,
  loadingStarted,
  loadingCompleted,
} = formSearchSlice.actions;

export default formSearchSlice.reducer;
