import React from "react";
import { IFormGridModel } from "../../../models/formModels/IFormGridModel";
import { baseUrl } from "../../../apis/formApi";
import {
  getAppNameByExtension,
  isNullOrUndefinedOrWhiteSpace,
} from "../../../utils/stringUtils";
import { fdotPrimaryButton } from "../../forms/forms";

interface IProps {
  gridDataItem: IFormGridModel;
  onOtherClick?: (x: string) => void;
}

const FormsGridAttachmentCell = (props: IProps) => {
  const { gridDataItem, onOtherClick } = props;
  const buttonElement: Array<JSX.Element> = [];
  const externalUrl = gridDataItem.externalUrl;
  const attachments = gridDataItem.attachments;
  const extRefMessage = gridDataItem.externalReferenceMessage;
  const purpose = gridDataItem.externalReference ?? "";

  const onOtherChildClick = () => {
    if (onOtherClick) {
      onOtherClick(purpose);
    }
  };

  if (gridDataItem.consolidatedForm) {
    buttonElement.push(
      <a
        id={`linkConsolidated`}
        key={`linkConsolidated}`}
        href={`/Forms/ConsolidatedForm/${gridDataItem._id}`}
        target="_blank"
        rel="noopener noreferrer"
        title={`Opens consolidated form in new window`}
        className="btn btn-primary btn-sm text-white"
        style={fdotPrimaryButton}
      >
        Consolidated
      </a>
    );
  } else {
    if (attachments && attachments.length !== 0) {
      attachments.forEach((a) => {
        if (a.docId !== undefined && a.isAvailable) {
          const appName = getAppNameByExtension(a.extension);
          buttonElement.push(
            <a
              href={`${baseUrl}/downloadAttachment/${a.docId}`}
              key={`${appName}_${a.docId}`}
              title={`Downloads attachment to your browser. Attachment name is ${a.fileName} (${a.fileSize} bytes).`}
              id={`${appName}_${a.docId}`}
              className="btn btn-primary btn-sm text-white"
              rel="noopener noreferrer"
              style={fdotPrimaryButton}
            >
              {a.isInstructionsPDF ? "Instructions" : appName}
            </a>
          );
        }
      });
    }

    if (!isNullOrUndefinedOrWhiteSpace(externalUrl)) {
      buttonElement.push(
        <a
          id={`link_${externalUrl}`}
          key={`link_${externalUrl}`}
          href={externalUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={`Opens link in new window - ${externalUrl}`}
          className="btn btn-primary btn-sm text-white"
          style={fdotPrimaryButton}
        >
          Link
        </a>
      );
    }

    if (extRefMessage) {
      buttonElement.push(
        <button
          id={`message_${purpose}`}
          key={`message_${purpose}`}
          title="External Message"
          className="btn btn-primary btn-sm text-white"
          onClick={onOtherChildClick}
          style={fdotPrimaryButton}
        >
          Other
        </button>
      );
    }
  }
  return buttonElement.length === 0 ? <td></td> : <td>{buttonElement}</td>;
};

export default FormsGridAttachmentCell;
