import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAnnouncement } from "../../models/announcement/IAnnouncement";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/announcement`;

export const announcementApi = createApi({
  reducerPath: "announcement",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: ["announcement"],
  endpoints: (builder) => ({
    getAnnouncements: builder.query<IAnnouncement[], string>({
      query: (filter) => `${baseUrl}/${filter}`,
      providesTags: ["announcement"],
    }),
    addAnnouncement: builder.mutation<IAnnouncement, IAnnouncement>({
      query: (body) => ({
        url: baseUrl,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["announcement"],
    }),
    deleteAnnouncement: builder.mutation<void, IAnnouncement>({
      query: (body) => ({
        url: baseUrl,
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["announcement"],
    }),
  }),
});

export const {
  useAddAnnouncementMutation,
  useGetAnnouncementsQuery,
  useDeleteAnnouncementMutation,
} = announcementApi;
