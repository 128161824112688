import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faMinusCircle,
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faInfoCircle,
  faArrowCircleRight,
  faFilePdf,
  faDownload,
  faPaperPlane,
  faExclamationTriangle,
  faSearch,
  faComment,
  faUndo,
  faFileExcel,
  faArrowAltCircleLeft,
  faHistory,
  faPen,
  faEye,
  faArrowCircleUp,
  faStream,
  faTrash,
  faExclamationCircle,
  faBookOpen,
  faCertificate,
  faCloudUploadAlt,
  faLocationArrow,
  faRecycle,
  faQuestionCircle,
  faChartLine,
  faPenSquare,
  faCheckDouble,
  faArchive,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

export const initializeIcons = () => {
  library.add(
    faPlusCircle,
    faMinusCircle,
    faTrashAlt,
    faCheckCircle,
    faTimesCircle,
    faEdit,
    faInfoCircle,
    faArrowCircleRight,
    faFilePdf,
    faDownload,
    faPaperPlane,
    faExclamationTriangle,
    faSearch,
    faComment,
    faUndo,
    faFileExcel,
    faArrowAltCircleLeft,
    faHistory,
    faPen,
    faEye,
    faArrowCircleUp,
    faTrash,
    faStream,
    faExclamationCircle,
    faBookOpen,
    faCertificate,
    faCloudUploadAlt,
    faLocationArrow,
    faRecycle,
    faQuestionCircle,
    faChartLine,
    faPenSquare,
    faCheckDouble,
    faArchive,
    faBan
  );
};
