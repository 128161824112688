import {
  Grid,
  GridColumn,
  GridDetailRowProps,
  GridToolbar,
} from "@progress/kendo-react-grid";
import React, { useState, useEffect } from "react";
import { process, filterBy } from "@progress/kendo-data-query";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  IGridDataSortOrder,
  getGridDataState,
} from "../common/grid/gridDataState";
import { IHomepageDocumentGridModel } from "../../models/IHomepageDocumentGridModel";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { IHomepageDocument } from "../../models/IHomepageDocument";
import { searchDocuments } from "../../apis/homepageApi";
import { mapHomepageGridData } from "../common/gridDataHelper";
import { appInsights } from "../../utils/initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { LoadingImage } from "../common/loadingImage";
import {
  customCellRender,
  customHeaderRender,
} from "../common/grid/customCellRender";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import FormsGridAttachmentCell from "../common/grid/formsGridAttachmentCell";
import {
  getAppNameByExtension,
  getAppNameByExtensionProcedure,
  isNullOrUndefinedOrWhiteSpace,
} from "../../utils/stringUtils";
import { IStatus } from "../../models/procedureModels/IStatus";
import { baseUrl } from "../../apis/procedureApi";
import { fdotPrimaryButton } from "../forms/forms";

interface IProps {
  documentName: string;
  documentNumber: string;
  documentOwner: string;
  documentOffice: string;
  isOpen: boolean;
  isOpenChanged?: (isOpen: boolean) => void;
}

const Attachments = ({ dataItem, onClick, isAuthenticated }: any) => {
  if (dataItem.documentType === "Form") {
    return (
      <FormsGridAttachmentCell
        gridDataItem={dataItem}
        onOtherClick={(purpose: string) => {
          onClick(dataItem, purpose);
        }}
      />
    );
  } else {
    const item: IHomepageDocument = dataItem;
    let buttonElement: Array<JSX.Element> = [];
    const link = item.documentLink;
    const attachments = item.attachments;
    if (attachments && attachments.length !== 0) {
      for (const a of attachments) {
        if (a.docId !== undefined && a.isAvailable) {
          if (isAuthenticated) {
            const docType = getAppNameByExtension(a.extension).toUpperCase();
            const current =
              item.status === IStatus.Current ||
              item.status === IStatus.CurrentAndRevision;
            const fileName = current
              ? item.topicNumber.concat(".", a.extension)
              : a.fileName;
            buttonElement.push(
              <a
                href={
                  docType === "WORD"
                    ? current
                      ? `${baseUrl}/downloadProcedureMaster/${item.topicNumber}`
                      : `${baseUrl}/downloadAttachment/${a.docId}`
                    : current
                    ? `${baseUrl}/downloadProcedure/${item.topicNumber}`
                    : `${baseUrl}/downloadAttachment/${a.docId}`
                }
                key={a.docId}
                title={`Downloads attachment to your browser. Attachment name is ${fileName} (${a.fileSize} bytes).`}
                id={a.docId!.toString()}
                className="btn btn-primary btn-sm text-white"
                rel="noopener noreferrer"
                style={fdotPrimaryButton}
              >
                {getAppNameByExtensionProcedure(a.extension, item.documentType)}
              </a>
            );
          } else {
            if (getAppNameByExtension(a.extension).toUpperCase() !== "WORD") {
              buttonElement.push(
                <a
                  href={`${baseUrl}/downloadProcedure/${item.topicNumber}`}
                  key={a.docId}
                  title={`Downloads attachment to your browser. Attachment name is ${item.topicNumber}.${a.extension} (${a.fileSize} bytes).`}
                  id={a.docId!.toString()}
                  className="btn btn-primary btn-sm text-white"
                  rel="noopener noreferrer"
                  style={fdotPrimaryButton}
                >
                  {getAppNameByExtensionProcedure(
                    a.extension,
                    item.documentType
                  )}
                </a>
              );
            }
          }
        }
      }
    }

    if (!isNullOrUndefinedOrWhiteSpace(link)) {
      buttonElement.push(
        <a
          id={`link_${link}`}
          key={`link_${link}`}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          title={`Opens link in new window - ${link}`}
          className="btn btn-primary btn-sm text-white"
          style={fdotPrimaryButton}
        >
          Link
        </a>
      );
    }

    return buttonElement.length === 0 ? <td></td> : <td>{buttonElement}</td>;
  }
};

const HomepageSearchModal = (props: IProps) => {
  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  const {
    documentName,
    documentNumber,
    documentOwner,
    documentOffice,
    isOpen,
    isOpenChanged,
  } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    if (isOpenChanged) {
      isOpenChanged(!modal);
    }
  };

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Array<IHomepageDocumentGridModel>>([]);
  const [dataState, setDataState] = useState(
    getGridDataState("formattedBeginDate", IGridDataSortOrder.desc, true, 20)
  );

  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  useEffect(() => {
    setModal(isOpen);
    if (isOpen) {
      const getLatestDocumentsFromApi = async () => {
        try {
          setLoading(true);
          let documents: IHomepageDocument[];
          const criteria = {
            documentName: documentName,
            documentNumber: documentNumber,
            documentOwner: documentOwner,
            documentOffice: documentOffice,
          };

          documents = await searchDocuments(criteria);
          const mappedGridData = mapHomepageGridData(documents, []);
          setResults(mappedGridData);
          setLoading(false);
        } catch (error: any) {
          toast.error("Error while searching. Please try again later.");
          setLoading(false);
          appInsights.trackException({
            error,
            id: "Search All Documents",
            severityLevel: SeverityLevel.Critical,
            properties: {
              serverResponse: `${error.response}`,
            },
          });
        }
      };

      getLatestDocumentsFromApi();
    }
  }, [!isOpen]);

  const topicNumberCell = (event: any) => {
    const item = event.dataItem;
    return isAuthenticated && user?.isAdmin ? (
      <td className="centeredColumn">
        <a
          id={`view_${item._id}`}
          title={`Opens in new window - ${item.topicNumber}`}
          href={`/${item.linkType}/edit/${item._id}`}
          target="_blank"
          rel="noreferrer noopener"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#33557c",
          }}
        >
          {item.topicNumber}
        </a>
      </td>
    ) : (
      <td className="centeredColumn">{item.topicNumber}</td>
    );
  };

  const purposeDetails = (event: GridDetailRowProps) => (
    <section>
      <p>{event.dataItem.externalReference}</p>
    </section>
  );

  const expandChange = (dataItem: any) => {
    let newData = results.map((item) => {
      if (item._id === dataItem._id) {
        (item as any).expanded = !dataItem.expanded;
      }
      return item;
    });
    setResults(newData);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered modal-xl"
      >
        <ModalHeader toggle={toggle}>Document Search</ModalHeader>
        <ModalBody>
          {loading ? (
            <LoadingImage />
          ) : (
            <ExcelExport
              data={filterBy(results, dataState.filter!)}
              ref={(exporter) => {
                _export = exporter;
              }}
              fileName="Search Results"
            >
              <Grid
                sortable
                filterable
                resizable
                reorderable
                pageable={{
                  buttonCount: 4,
                  pageSizes: [10, 20, 50, 100, results.length],
                }}
                data={process(results, dataState)}
                {...dataState}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                }}
                cellRender={customCellRender}
                headerCellRender={customHeaderRender}
                detail={purposeDetails}
                expandField="expanded"
              >
                <GridToolbar className="k-text-end">
                  <div className="row">
                    <div className="col-lg-12">
                      <button
                        type="button"
                        title="Export to Excel"
                        className="btn btn-primary"
                        onClick={exportExcel}
                      >
                        <FontAwesomeIcon icon="file-excel" /> Export to Excel
                      </button>
                    </div>
                  </div>
                </GridToolbar>
                <GridColumn
                  className="centeredColumn"
                  field="topicNumber"
                  title="Topic Number"
                  cell={topicNumberCell}
                />
                <GridColumn
                  className="centeredColumn"
                  field="documentTitle"
                  title="Document Title"
                />
                <GridColumn
                  className="centeredColumn"
                  field="documentType"
                  title="Document Type"
                />
                <GridColumn
                  className="centeredColumn"
                  field="programArea"
                  title="Program Area"
                />
                <GridColumn
                  className="centeredColumn"
                  field="office"
                  title="Office"
                />
                <GridColumn
                  className="centeredColumn"
                  field="DocumentOwner"
                  title="Document Owner"
                />
                <GridColumn
                  headerClassName="text-wrap"
                  className="centeredColumn"
                  title="Available Formats"
                  sortable={false}
                  filterable={false}
                  cell={(props) => (
                    <Attachments {...props} onClick={expandChange} />
                  )}
                />
              </Grid>
            </ExcelExport>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default HomepageSearchModal;
