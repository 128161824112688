import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IOffice } from "../../models/officeModels/IOffice";
import { IProgramAreaOffice } from "../../models/areaOfficeModels/IProgramAreaOffice";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/offices`;
export const officeApi = createApi({
  reducerPath: "officeApi",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: ["Office", "area-office"],
  endpoints: (build) => ({
    getOffices: build.query<IOffice[], void>({
      query: () => `/getOffices`,
      providesTags: ["Office"],
    }),
    getProgramAreaOffices: build.query<IProgramAreaOffice[], void>({
      query: () => `${baseUrl}/area-offices`,
      providesTags: ["area-office"],
    }),
  }),
});

export const { useGetOfficesQuery, useGetProgramAreaOfficesQuery } = officeApi;
