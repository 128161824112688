import axios from "axios";
import { IForm } from "../models/formModels/IForm";
import { IEdmsModal } from "../models/formModels/IEdmsModal";
import { IFormComment } from "../models/formModels/IFormComment";
import { IContactUs, IUserRole } from "procedural-document-library-shared";
import { IComment } from "../models/formModels/IComment";

export const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/form`;

const multipartHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const getExternalForms = async (): Promise<IForm[]> => {
  const response = await axios.get(`${baseUrl}/externalForms`);
  return response.data;
};

export const getInternalForms = async (): Promise<IForm[]> => {
  const response = await axios.get(`${baseUrl}/internalForms`);
  return response.data;
};

export const saveForm = async (formData: FormData) => {
  const response = await axios.post(`${baseUrl}/saveForm`, formData);
  return response.data;
};

export const updateForm = async (form: FormData) => {
  const response = await axios.patch(`${baseUrl}/updateForm`, form);
  return response.data;
};

export const uploadDocument = async (
  formData: FormData
): Promise<Array<IEdmsModal>> => {
  const response = await axios.post(
    `${baseUrl}/uploadFile`,
    formData,
    multipartHeaders
  );
  return response.data.fileResults;
};

export const updateDocument = async (
  formData: FormData,
  formId: string
): Promise<IForm> => {
  const response = await axios.post(
    `${baseUrl}/updateDocument/${formId}`,
    formData
  );
  return response.data;
};

export const getFormById = async (id: string): Promise<IForm> => {
  const response = await axios.get(`${baseUrl}/getFormById/${id}`);
  return response.data;
};

export const getFormByIdExternal = async (id: string): Promise<IForm> => {
  const response = await axios.get(`${baseUrl}/getFormByIdExternal/${id}`);
  return response.data;
};

// this method is used in axios concurrent requests
// the above and this methods are same
export const getFormByIdPromise = (id: string) => {
  return axios.get(`${baseUrl}/getFormById/${id}`);
};

export const getAdminForms = async (): Promise<Array<IForm>> => {
  const response = await axios.get(`${baseUrl}/adminForms`);
  return response.data;
};

export const filterForms = async (
  srsId: string | null,
  includeInactive: boolean,
  viewMode: IUserRole,
  searchTerm: string | null
) => {
  const response = await axios.get(
    `${baseUrl}/filterForms/${srsId}/${includeInactive}/${viewMode}/${searchTerm}`
  );
  return response.data;
};

export const deleteAttachmentById = async (docId: number, formId: string) => {
  return await axios.delete(
    `${baseUrl}/deleteAttachmentById/${docId}/${formId}`
  );
};

export const getFormQuestionComment = async (id: string): Promise<IForm> => {
  const response = await axios.get(`${baseUrl}/formQuestionComment/${id}`);
  return response.data;
};

export const submitFormQuestionComment = async (formValues: IFormComment) => {
  const response = await axios.post(
    `${baseUrl}/formQuestionComment`,
    formValues
  );
  return response.data;
};

export const getFormsForContactOwners = async (): Promise<Array<IForm>> => {
  const response = await axios.get(`${baseUrl}/contactFormOwners`);
  return response.data;
};

export const submitContactFormOwners = async (formData: FormData) => {
  const response = await axios.post(`${baseUrl}/contactFormOwners`, formData);
  return response.data;
};

export const getFormByText = async (
  searchText: string,
  excludeSearchId: string | null,
  includeOnlyStatus: string | null
): Promise<Array<IForm>> => {
  const response = await axios.get(
    `${baseUrl}/getFormByText/${searchText}/${excludeSearchId}/${includeOnlyStatus}`
  );
  return response.data;
};

export const getCommentsByFormId = async (formId: string) => {
  const response = await axios.get(`${baseUrl}/comments/${formId}`);
  return response.data;
};

export const submitFormComment = async (comment: IComment, formId: string) => {
  const response = await axios.post(
    `${baseUrl}/formComment/${formId}`,
    comment
  );
  return response.data;
};

export const updateFormComment = async (
  comment: IComment,
  formId: string,
  commentId: string
) => {
  const response = await axios.patch(
    `${baseUrl}/updateComment/${formId}/${commentId}`,
    comment
  );
  return response.data;
};

export const getScheduledReviewsReport = async () => {
  const response = await axios.get(`${baseUrl}/scheduledReviewsReport`);
  return response.data;
};

export const checkIfFormExists = async (formNumber: string, formId: string) => {
  const response = await axios.get(
    `${baseUrl}/checkIfFormExists/${formNumber}/${formId}`
  );
  return response.data;
};

export const getStatusUpdatedReport = async () => {
  const response = await axios.get(`${baseUrl}/getStatusUpdatedForms`);
  return response.data;
};

export const submitContactUs = async (contactUs: IContactUs) => {
  const response = await axios.post(`${baseUrl}/contactUs`, contactUs);
  return response.data;
};
