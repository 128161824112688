import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IRequestFormAction } from "../../models/formModels/IRequestFormAction";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/request-form-action`;

export const requestFormActionApi = createApi({
  reducerPath: "requestFormAction",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: ["request-form-action"],
  endpoints: (builder) => ({
    getRequestForms: builder.query<IRequestFormAction[], void>({
      query: () => baseUrl,
      providesTags: ["request-form-action"],
    }),
    getRequestFormById: builder.query<IRequestFormAction, string>({
      query: (id) => `${baseUrl}/${id}`,
      providesTags: (result, error, id) => [
        { type: "request-form-action", id },
      ],
    }),
    addRequestForm: builder.mutation<IRequestFormAction, FormData>({
      query: (formData) => ({
        url: baseUrl,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["request-form-action"],
    }),
    updateRequestForm: builder.mutation<
      IRequestFormAction,
      { id: string; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${baseUrl}/${id}`,
        body: formData,
        method: "PATCH",
      }),
      invalidatesTags: ["request-form-action"],
    }),
    markCompleteRequestForm: builder.mutation<IRequestFormAction, string>({
      query: (id) => ({
        url: `${baseUrl}/complete/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["request-form-action"],
    }),
    publishRequest: builder.mutation<
      IRequestFormAction,
      { id: string; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${baseUrl}/publish/${id}`,
        body: formData,
        method: "POST",
      }),
      invalidatesTags: ["request-form-action"],
    }),
  }),
});

export const {
  useGetRequestFormsQuery,
  useGetRequestFormByIdQuery,
  useAddRequestFormMutation,
  useUpdateRequestFormMutation,
  usePublishRequestMutation,
  useMarkCompleteRequestFormMutation,
} = requestFormActionApi;
