import { IUser, IUserRole } from "procedural-document-library-shared";

export const getUserRole = (
  user: IUser | undefined,
  isAuthenticated: boolean
) => {
  let userRole = IUserRole.External;
  if (user) {
    userRole = IUserRole.External;
  }

  if (user && isAuthenticated) {
    userRole = IUserRole.Internal;
  }

  if (user && isAuthenticated && user.isAdmin) {
    userRole = IUserRole.Admin;
  }

  return userRole;
};
