import React, { useState } from "react";
import "../../../App.css";

interface IProps {
  tdProps: any;
}

export const FocusableCell = (props: IProps) => {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <td
      {...props.tdProps}
      style={{
        background: focus ? "lightgray" : undefined,
        ...props.tdProps.style,
      }}
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="centeredColumn"
    />
  );
};
