import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IForm } from "../../models/formModels/IForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  utcToDateString,
  getFullContactInformation,
} from "../../utils/stringUtils";
import history from "../../utils/history";
import { useNavigate } from "react-router-dom";

interface IProps {
  form: IForm;
  isOpen: boolean;
  isOpenChanged?: (isOpen: boolean) => void;
}

const FormDetailsModal = (props: IProps) => {
  const navigate = useNavigate();
  const { form, isOpen, isOpenChanged } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    if (isOpenChanged) {
      isOpenChanged(!modal);
    }
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <>
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={toggle}>
            {form !== undefined
              ? `Form Details - ${form.formTitle}`
              : "Form Details"}
          </ModalHeader>
          <ModalBody>
            {form !== undefined ? (
              <>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="owner" title="Owner">
                        Owner
                      </label>
                      <textarea
                        title="Owner"
                        value={getFullContactInformation(form.contact ?? null)}
                        id="owner"
                        name="owner"
                        readOnly
                        className="form-control"
                        rows={3}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="purpose" title="Purpose">
                        Purpose
                      </label>
                      <textarea
                        title="Purpose"
                        value={form.purpose}
                        id="purpose"
                        name="purpose"
                        readOnly
                        className="form-control"
                        rows={3}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="Program Area" title="programArea">
                        Program Area
                      </label>
                      <input
                        title="Program Area"
                        type="text"
                        value={form.programArea}
                        id="programArea"
                        name="programArea"
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="office" title="Office">
                        Office
                      </label>
                      <input
                        title="Office"
                        type="text"
                        value={form.office}
                        id="office"
                        name="office"
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="revisionDate" title="Revision Date">
                        Revision Date
                      </label>
                      <input
                        title="Revision Date"
                        type="text"
                        value={utcToDateString(form.revisionDate)}
                        id="revisionDate"
                        name="revisionDate"
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p>No Form Details Found.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              id="submitQuestion"
              onClick={() => navigate(`/Form/QuestionComment/${form._id}`)}
            >
              <FontAwesomeIcon icon="arrow-circle-right" /> Submit
              Question/Comment to Admin Team
            </button>
            <button id="cancel" className="btn btn-danger" onClick={toggle}>
              <FontAwesomeIcon icon="times-circle" /> Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default FormDetailsModal;
