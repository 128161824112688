export const formatPhoneNumber = (
  phoneNumber: string | undefined,
  extension: string | null | undefined
): string => {
  if (phoneNumber === undefined) {
    return "";
  }
  if (phoneNumber.length === 10) {
    const formatted = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
      3,
      3
    )}-${phoneNumber.substring(6)}`;
    return extension !== undefined && extension !== null && extension.length > 0
      ? `${formatted} (${extension})`
      : formatted;
  } else if (phoneNumber.length === 7) {
    const formatted = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
      3,
      4
    )}`;
    return extension !== undefined && extension !== null && extension.length > 0
      ? `${formatted} (${extension})`
      : formatted;
  } else {
    console.log(`Implement formatPhoneNumber logic for: ${phoneNumber}`);
    return phoneNumber;
  }
};
