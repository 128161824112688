import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { IProps } from "../common/loadingImage";
import Announcements from "./announcements";
import DocumentReviewReport from "./documentReviewReport";
import QuickLinks from "./quickLinks";
import SearchAllDocuments from "./searchAllDocuments";
import localforage from "localforage";

const HomePage = (props: IProps) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );
  // Adding Deep Linking functionality to capture the URL the user initially tried to access before being authenticated
  const redirectIfUserInitiallyTriedToDeepLinkBeforeAuthenticated = () => {
    localforage.getItem("deep-link-redirect-url").then((redirectUrl: any) => {
      if (redirectUrl) {
        localforage.removeItem("deep-link-redirect-url").then(() => {
          window.location.href = redirectUrl;
        });
      }
    });
  };

  redirectIfUserInitiallyTriedToDeepLinkBeforeAuthenticated();

  return (
    <>
      <div className="row mb-3">
        <div className="col-lg-4">
          <Announcements />
        </div>
        <div className="col-lg-4">
          <QuickLinks />
        </div>
        <div className="col-lg-4">
          <SearchAllDocuments />
        </div>
      </div>
      {isAuthenticated && (
        <div className="row mb-3">
          <div className="col-lg-12">
            <DocumentReviewReport />
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
