import axios from "axios";
import { IAppConfig } from "procedural-document-library-shared";
import { IClientConfig } from "../models/IClientConfig";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/config`;

export const getConfig = async (): Promise<IAppConfig> => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getClientConfig = async (): Promise<IClientConfig> => {
  const response = await axios.get(`${baseUrl}/clientConfig`);
  return response.data;
};
