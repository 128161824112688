import React, { useEffect, Suspense, CSSProperties } from "react";
import { fetchConfig } from "./redux/slices/configSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./redux/rootReducer";
import { LoadingImage } from "./components/common/loadingImage";
import { fetchUser } from "./redux/slices/userSlice";
import Loading from "react-loading";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { initializeApp } from "./utils/initializer";
import HomePage from "./components/home/homePage";

const NotAuthorized = React.lazy(() => import("./components/notAuthorized"));
const NoMatch = React.lazy(() => import("./components/noMatch"));
const Staff = React.lazy(() => import("./components/staff/staff"));
const Impersonate = React.lazy(() => import("./components/impersonate"));
const ContactUs = React.lazy(() => import("./components/contactUs"));
const Forms = React.lazy(() => import("./components/forms/forms"));
const Procedures = React.lazy(
  () => import("./components/procedures/procedures")
);

const FormAddEditForm = React.lazy(
  () => import("./components/forms/formAddEditForm")
);
const ProcedureAddEdit = React.lazy(
  () => import("./components/procedures/addEditProcedure")
);
const VersionAddEdit = React.lazy(
  () => import("./components/procedures/addEditVersion")
);
const FormQuestionComment = React.lazy(
  () => import("./components/forms/formQuestionComment")
);
const ProcedureQuestionComment = React.lazy(
  () => import("./components/procedures/procedureQuestionComment")
);
const ContactFormOwners = React.lazy(
  () => import("./components/forms/contactFormOwners")
);
const ConsolidatedForm = React.lazy(
  () => import("./components/forms/consolidatedForm")
);
const Comments = React.lazy(() => import("./components/forms/comments"));
const FormsScheduledReviewsReport = React.lazy(
  () => import("./components/forms/scheduledReviewsReport")
);
const ProceduralMasterDataReport = React.lazy(
  () => import("./components/procedures/masterDataReport")
);

const Reviews = React.lazy(() => import("./components/reviews/reviewTabs"));

const OnlineReview = React.lazy(
  () => import("./components/reviews/addEditReview")
);
const ReviewComments = React.lazy(
  () => import("./components/reviewComments/comments")
);

const PPMUReviewForm = React.lazy(
  () => import("./components/reviews/ppmuReviewForm")
);

const LegalReviewForm = React.lazy(
  () => import("./components/reviews/legalReviewForm")
);

const DirectorReviewForm = React.lazy(
  () => import("./components/reviews/directorReviewForm")
);

const ChiefEquivalentReviewForm = React.lazy(
  () => import("./components/reviews/chiefEquivalentReviewForm")
);

const AssistantSecretaryReviewForm = React.lazy(
  () => import("./components/reviews/assistantSecretaryReviewForm")
);

const ExecutiveWorkshoReviews = React.lazy(
  () => import("./components/reviews/executiveWorkshopReviews")
);

const ExecutiveWorkshopDecision = React.lazy(
  () => import("./components/reviews/executiveWorkshopDecision")
);

const StatusUpdatedReport = React.lazy(
  () => import("./components/forms/statusUpdatedReport")
);

const FormSignDocument = React.lazy(
  () => import("./components/docusign/formSignDocument")
);

const LoginDocuSignForm = React.lazy(
  () => import("./components/docusign/login")
);

const RequestFormActions = React.lazy(
  () => import("./components/request-form-actions/requestFormActions")
);
const RequestFormAction = React.lazy(
  () => import("./components/request-form-actions/requestFormAction")
);
const EditRequestFormAction = React.lazy(
  () => import("./components/request-form-actions/editRequestFormAction")
);

const RequestFormActionReview = React.lazy(
  () => import("./components/request-form-actions/reviewFormAction")
);

const DocumentContactChanges = React.lazy(
  () => import("./components/document-contact-change/documentContactChanges")
);

const EditDocumentContactChange = React.lazy(
  () => import("./components/document-contact-change/editDocumentContactChange")
);

const SearchDocumentContactChange = React.lazy(
  () =>
    import("./components/document-contact-change/documentContactChangeSearch")
);

const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
};

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();

  const { loadingConfig, config } = useSelector(
    (state: RootState) => state.configReducer
  );

  const { isUserLoaded, isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchConfig());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (config && user) {
      initializeApp(config, user.displayName);
    }
  }, [config, user]);

  if (!isUserLoaded || loadingConfig) {
    return <LoadingImage></LoadingImage>;
  }

  const getMainLandingPage = (): JSX.Element => {
    return <Route path="/" element={<HomePage />} />;
  };

  const getAdminOrDocumentOwnerRoutes = () => {
    const routes: JSX.Element[] = [];
    if (user && isAuthenticated) {
      routes.push(
        <Route
          path="/request-form-actions"
          key="request-form-actions"
          element={<RequestFormActions />}
        />,
        <Route
          path="/request-form-action/new"
          key="request-form-action"
          element={<RequestFormAction />}
        />,
        <Route
          path="/request-form-action/:id"
          key="edit-request-form-action"
          element={<EditRequestFormAction />}
        />
      );
    }

    return routes;
  };

  const getAdminRoutes = (): JSX.Element[] => {
    if (user !== undefined && isAuthenticated && user.isAdmin) {
      const routes: JSX.Element[] = [];
      const mustAuthenticate =
        user?.docusignUser?.docuSignAuthenticationRequired === true;
      if (!mustAuthenticate) {
        routes.push(
          <Route
            path={"/Forms/Document/Sign"}
            key="Sign"
            element={<FormSignDocument />}
          />
        );
      }
      routes.push(
        <Route
          path={"/Forms/DocuSign/Login"}
          key="DocuLogin"
          element={<LoginDocuSignForm />}
        />
      );

      routes.push(
        <Route path="/Forms/Create" key="Form" element={<FormAddEditForm />} />
      );

      routes.push(
        <Route
          path="/Forms/edit/:id"
          key="Edit"
          element={<FormAddEditForm />}
        />
      );

      routes.push(
        <Route
          path="/Procedures/Create"
          key="Procedure"
          element={<ProcedureAddEdit />}
        />
      );

      routes.push(
        <Route
          path="/Procedures/edit/:id"
          key="Edit"
          element={<ProcedureAddEdit />}
        />
      );

      routes.push(
        <Route
          path="/Version/edit/:id/:procedureId"
          key="Edit"
          element={<VersionAddEdit />}
        ></Route>
      );
      routes.push(
        <Route
          path="/Version/Add/:procedureId"
          key="Add"
          element={<VersionAddEdit />}
        ></Route>
      );

      routes.push(
        <Route
          path="/Forms/ContactFormsOwners"
          key="ContactFormsOwners"
          element={<ContactFormOwners />}
        />
      );

      routes.push(
        <Route
          path="/Forms/Comments/:id"
          key="FormComments"
          element={<Comments />}
        />
      );

      routes.push(
        <Route
          path="/Forms/ScheduledReviewsReport"
          key="ScheduledReviewsReport"
          element={<FormsScheduledReviewsReport />}
        />
      );

      routes.push(
        <Route
          path="/Procedures/MasterDataReport"
          key="MasterDataReport"
          element={<ProceduralMasterDataReport />}
        />
      );

      routes.push(
        <Route
          path="/Forms/StatusUpdatedReport"
          key="StatusUpdatedReport"
          element={<StatusUpdatedReport />}
        />
      );

      routes.push(
        <Route
          path="/Reviews/PPMUReview/:reviewId"
          key="PPMU Review"
          element={<PPMUReviewForm />}
        />
      );

      routes.push(
        <Route
          path="/Reviews/ExecutiveWorkshopReviews"
          key="Executive Workshop Reviews"
          element={<ExecutiveWorkshoReviews />}
        />
      );

      routes.push(
        <Route
          path="/Reviews/ExecutiveWorkshopDecision/:reviewId"
          key="Executive Workshop Decision"
          element={<ExecutiveWorkshopDecision />}
        />
      );

      routes.push(
        <Route
          path="/request-form-action/review/:id"
          key="request-form-action-review"
          element={<RequestFormActionReview />}
        />
      );

      return routes;
    } else {
      return [];
    }
  };

  const getAuthenticatedRoutes = (): JSX.Element[] => {
    if (!isAuthenticated) return [];
    const routes: Array<JSX.Element> = [];

    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      routes.push(<Route path="/Staff" key="Staff" element={<Staff />} />);
      routes.push(
        <Route
          path="/Impersonate"
          key="Impersonate"
          element={<Impersonate />}
        />
      );
    }

    routes.push(
      <Route path="/Forms/View/:id" key="View" element={<FormAddEditForm />} />
    );

    routes.push(<Route path="/Reviews" key="Reviews" element={<Reviews />} />);

    routes.push(
      <Route
        path="/Reviews/Create"
        key="CreateReview"
        element={<OnlineReview />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/edit/:id"
        key="EditReview"
        element={<OnlineReview />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/comments/:id"
        key="ReviewComment"
        element={<ReviewComments />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/AssistantSecretaryReview/:reviewId"
        key="Assistant Secretary Review"
        element={<AssistantSecretaryReviewForm />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/LegalReview/:reviewId"
        key="Legal Review"
        element={<LegalReviewForm />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/DirectorReview/:reviewId"
        key="Director Review"
        element={<DirectorReviewForm />}
      />
    );

    routes.push(
      <Route
        path="/Reviews/ChiefEquivalentReview/:reviewId"
        key="Chief Equivalent Review"
        element={<ChiefEquivalentReviewForm />}
      />
    );

    routes.push(
      <Route
        path="/document-contact-changes"
        key="document-contact-changes"
        element={<DocumentContactChanges />}
      />
    );
    routes.push(
      <Route
        path="/document-contact-change/new"
        key="document-contact-change"
        element={<SearchDocumentContactChange />}
      />
    );
    routes.push(
      <Route
        path="/document-contact-change/:id"
        key="document-contact-change"
        element={<EditDocumentContactChange />}
      />
    );

    return routes;
  };

  const getUnAuthenticatedRoutes = () => {
    const routes: Array<JSX.Element> = [];
    routes.push(<Route path="/Forms" key="Forms" element={<Forms />} />);
    routes.push(
      <Route path="/Procedures" key="Procedures" element={<Procedures />} />
    );
    routes.push(
      <Route path="/ContactUs" key="ContactUs" element={<ContactUs />} />
    );
    routes.push(
      <Route
        path="/Form/QuestionComment/:id"
        key="FormComment"
        element={<FormQuestionComment />}
      />
    );
    routes.push(
      <Route
        path="/Procedure/QuestionComment/:id"
        key="ProcedureComment"
        element={<ProcedureQuestionComment />}
      />
    );
    routes.push(
      <Route
        path="/Forms/ConsolidatedForm/:id"
        key="ConsolidatedForm"
        element={<ConsolidatedForm />}
      />
    );
    return routes;
  };

  return (
    <>
      <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
        Skip to main content
      </a>
      <Header />
      <div id="maincontent" style={{ paddingBottom: "200px" }}>
        <div className="container-fluid">
          <div className="row">
            <div role="main" className="col">
              <Suspense fallback={<Loading></Loading>}>
                <Routes>
                  {getMainLandingPage()}
                  <Route
                    path="/NotAuthorized"
                    element={<NotAuthorized />}
                  ></Route>
                  {getUnAuthenticatedRoutes()}
                  {getAuthenticatedRoutes()}
                  {getAdminRoutes()}
                  {getAdminOrDocumentOwnerRoutes()}
                  <Route element={<NoMatch />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AppRoutes;
