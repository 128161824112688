import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { RootState } from "../../redux/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useAddAnnouncementMutation,
  useGetAnnouncementsQuery,
  useDeleteAnnouncementMutation,
} from "../../redux/slices/announcementsApi";
import { IAnnouncement } from "../../models/announcement/IAnnouncement";
import { toast } from "react-toastify";
import { LoadingImage } from "../common/loadingImage";
import {
  utcToDateString,
  utcToDateStringWithTime,
  utcToISODateString,
} from "../../utils/stringUtils";
import { CustomCard } from "../common/customCard";

const Announcements = () => {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [filter, setFilter] = useState("Active");
  const { isError, isLoading, data, refetch } =
    useGetAnnouncementsQuery(filter);
  const [addAnnouncement] = useAddAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  const date = new Date();
  date.setHours(23, 59, 59, 0);
  const newAnnouncement = {
    id: "",
    attachments: [],
    body: "",
    expiresOn: "",
    title: "",
  };
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<IAnnouncement>(newAnnouncement as any as IAnnouncement);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    refetch();
  }, [filter]);

  const onSubmitHandler = async () => {
    if (
      selectedAnnouncement.title.trim() !== "" &&
      selectedAnnouncement.body.trim() !== "" &&
      (selectedAnnouncement.expiresOn as any) !== null &&
      (selectedAnnouncement.expiresOn as any as string) !== ""
    ) {
      try {
        await addAnnouncement(selectedAnnouncement).unwrap();
        toast.success("Announcement added successfully.");
        setErrors([]);
      } catch (error) {
        toast.error("Error while adding Announcement. Please try again later.");
      } finally {
        toggle();
      }
    } else {
      const err = [];
      if (selectedAnnouncement.title === "") {
        err.push("Title is Required.");
      }

      if (selectedAnnouncement.body === "") {
        err.push("Body is Required.");
      }

      if (
        (selectedAnnouncement.expiresOn as any as string) === "" ||
        (selectedAnnouncement.expiresOn as any) === null
      ) {
        err.push("Expires On is Required.");
      }

      setErrors(err);
    }
  };

  const cancelButton = () => (
    <button
      title="Cancel"
      className="btn btn-danger"
      onClick={() => {
        toggle();
        setErrors([]);
      }}
    >
      <FontAwesomeIcon icon="times-circle" /> Cancel
    </button>
  );

  const onDeleteHandler = async () => {
    try {
      await deleteAnnouncement(selectedAnnouncement).unwrap();
      toast.success("Announcement deleted successfully.");
      toggle();
    } catch (error) {
      toast.error("Error while deleting Announcement. Please try again later.");
      toggle();
    }
  };

  if (isError) {
    toast.error("Error loading Announcements. Please try again later");
  }

  if (isLoading) {
    return <LoadingImage />;
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {selectedAnnouncement.id === ""
            ? "Add New Announcement"
            : selectedAnnouncement.title}
        </ModalHeader>
        <ModalBody>
          {user?.isAdmin ? (
            <>
              <div className="row mb-3">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label
                      htmlFor="title"
                      title="Title"
                      className="requiredField"
                    >
                      Title
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="form-control"
                      title="Title"
                      value={selectedAnnouncement.title}
                      onChange={(e) =>
                        setSelectedAnnouncement({
                          ...selectedAnnouncement,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label
                      htmlFor="body"
                      className="requiredField"
                      title="Body"
                    >
                      Body
                    </label>
                    <textarea
                      name="body"
                      id="body"
                      rows={3}
                      className="form-control"
                      title="Body"
                      onChange={(e) =>
                        setSelectedAnnouncement({
                          ...selectedAnnouncement,
                          body: e.target.value,
                        })
                      }
                      value={selectedAnnouncement.body}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label
                      htmlFor="expiresOn"
                      className="requiredField"
                      title="Expires On"
                    >
                      Expires On
                    </label>
                    <input
                      type="date"
                      name="expiresOn"
                      id="expiresOn"
                      className="form-control"
                      title="Expires On"
                      min={utcToISODateString(new Date())}
                      value={utcToISODateString(selectedAnnouncement.expiresOn)}
                      onChange={(e) =>
                        setSelectedAnnouncement({
                          ...selectedAnnouncement,
                          expiresOn: e.target.valueAsDate!,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {errors.length > 0 && (
                <div className="row">
                  <ul>
                    {errors.map((e, i) => (
                      <li key={i} className="text-danger">
                        <strong>Error: </strong>
                        {e}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="row mb-3">
                <div className="col-lg-12">{selectedAnnouncement.body}</div>
              </div>
              <br />
              <div className="row mb-3">
                <div className="col-lg-12">
                  <p>
                    <strong>Expires On: </strong>{" "}
                    {utcToDateString(selectedAnnouncement.expiresOn)}
                  </p>
                </div>
              </div>
            </>
          )}
          {user?.isAdmin && selectedAnnouncement._id && (
            <>
              <br />
              <div className="row mb-3">
                <div className="col-lg-12">
                  <p>
                    <strong>Created By: </strong>{" "}
                    {`${selectedAnnouncement.createdBy?.firstName} ${
                      selectedAnnouncement.createdBy?.lastName
                    } on ${utcToDateStringWithTime(
                      selectedAnnouncement.createdDate
                    )}`}
                  </p>
                  {selectedAnnouncement.updatedBy && (
                    <p>
                      <strong>Last Updated By: </strong>{" "}
                      {`${selectedAnnouncement.updatedBy.firstName} ${
                        selectedAnnouncement.updatedBy.lastName
                      } on ${utcToDateStringWithTime(
                        selectedAnnouncement.updatedDate
                      )}`}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
          {user?.isAdmin ? (
            <>
              <div className="row mb-3">
                <div className="col-lg-6">
                  {selectedAnnouncement._id && (
                    <button
                      className="btn btn-danger"
                      title={"Delete"}
                      onClick={onDeleteHandler}
                    >
                      <FontAwesomeIcon icon="trash-alt" /> Delete
                    </button>
                  )}
                </div>
                <div className="col-lg-6 text-end">
                  <button
                    className="btn btn-primary text-end"
                    title={`${selectedAnnouncement._id ? "Update" : "Submit"}`}
                    onClick={onSubmitHandler}
                  >
                    <FontAwesomeIcon icon="check-circle" />{" "}
                    {selectedAnnouncement._id ? "Update" : "Submit"}
                  </button>
                  {cancelButton()}
                </div>
              </div>
            </>
          ) : (
            <div className="row mb-3">
              <div className="col-lg-12 text-end">{cancelButton()}</div>
            </div>
          )}
        </ModalFooter>
      </Modal>
      <br />
      <CustomCard headerText="Announcements">
        <div
          tabIndex={0}
          style={{ height: "400px", overflowY: "auto", overflowX: "hidden" }}
        >
          {user?.isAdmin && (
            <>
              <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-lg-6">
                  <select
                    name="announceFilters"
                    id="announceFilters"
                    title="Select a Filter"
                    className="form-control"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="All">All</option>
                    <option value="Active">Active</option>
                    <option value="Expired">Expired</option>
                  </select>
                </div>
                <div className="col-lg-6 text-end">
                  <button
                    type="button"
                    title="Add Announcement"
                    id="addAnnouncement"
                    className="btn btn-primary"
                    onClick={() => {
                      setSelectedAnnouncement(
                        newAnnouncement as any as IAnnouncement
                      );
                      setIsOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon="plus-circle" /> Announcement
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="row mb-3">
            <div className="col-lg-12">
              <div className="list-group">
                {data && data.length > 0 ? (
                  data.map((ann) => {
                    return (
                      <>
                        <div
                          key={ann._id}
                          className="card mb-1"
                          onClick={() => {
                            setSelectedAnnouncement(ann);
                            setIsOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="card-body">
                            <h5 className="mb-0" id={`${ann._id}`}>
                              {ann.title.length > 30
                                ? `${ann.title.slice(0, 30)} ...`
                                : ann.title}
                            </h5>
                            <small className="text-muted">{`Expires on ${utcToDateString(
                              ann.expiresOn
                            )}`}</small>
                            <p className="card-text mb-1">
                              {ann.body.length > 150
                                ? `${ann.body.slice(0, 150)}...`
                                : ann.body}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="text-center">No Announcements found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CustomCard>
    </>
  );
};

export default Announcements;
